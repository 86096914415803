





















































import Vue from "vue";
import _ from "lodash";
import Requester from "@/requester";

import CustomFormActions from "../CustomFormActions.vue";
import { rulesCnpj, rulesRequired } from "@/utils/rules";
import {
  FornecedorCriarFormData,
  FornecedorAlterarFormData,
} from "../../../../back/src/types/formData";
import { Fornecedor } from "../../../../back/src/models/Fornecedor";
export default Vue.extend({
  name: "FornecedoresFormCriarAlterar",
  props: {
    fornecedor: {
      type: Object as () => Fornecedor,
      default: null,
    },
  },
  components: {
    CustomFormActions,
  },
  data() {
    return {
      form: {
        id: null,
        nome: null,
        cnpj: null,
        telefone: null,
        email: null,
        infoContato: null,
      },
      rules: {
        required: rulesRequired,
        cnpj: [...rulesRequired, ...rulesCnpj],
      },
      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    this.preencheForm();
  },
  methods: {
    preencheForm() {
      if (this.fornecedor) {
        this.form.id = this.fornecedor.id;
        this.form.nome = this.fornecedor.nome;
        this.form.cnpj = this.fornecedor.cnpj;
        this.form.telefone = this.fornecedor.telefone;
        this.form.email = this.fornecedor.email;
        this.form.infoContato = this.fornecedor.info_contato;
      } else {
        this.form.id = null;
        this.form.nome = null;
        this.form.cnpj = null;
        this.form.telefone = null;
        this.form.email = null;
        this.form.infoContato = null;
      }
    },
    cancelarCadastro() {
      this.$emit("cancelar");
      this.$refs.formFornecedores.reset();
    },
    submitDebounce: _.debounce(async function () {
      if (!this.$refs.formFornecedores.validate()) {
        return;
      }
      this.loading = true;
      let fornecedorResponse;
      let msgText = "Fornecedor criado com sucesso";
      let event = "fornecedor-criado";
      try {
        if (this.fornecedor) {
          event = "fornecedor-alterado";
          msgText = "Fornecedor alterado com sucesso";
          fornecedorResponse = await Requester.laboratorio.private.alterarFornecedor(
            this.user.laboratorio_id,
            {
              id: this.form.id,
              nome: this.form.nome,
              cnpj: this.form.cnpj,
              telefone: this.form.telefone,
              email: this.form.email,
              info_contato: this.form.infoContato,
            } as FornecedorAlterarFormData,
          );
        } else {
          fornecedorResponse = await Requester.laboratorio.private.criarFornecedor(
            this.user.laboratorio_id,
            {
              laboratorio_id: this.user.laboratorio_id,
              nome: this.form.nome,
              cnpj: this.form.cnpj,
              telefone: this.form.telefone,
              email: this.form.email,
              info_contato: this.form.infoContato,
            } as FornecedorCriarFormData,
          );
        }
        this.$root.$emit("showToast", {
          text: msgText,
          color: "success",
        });
        this.$emit(event, fornecedorResponse);
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    }, 200),
  },
  watch: {
    fornecedor() {
      this.preencheForm();
    },
  },
});
