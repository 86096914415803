















































































import Vue from "vue";
import Requester from "@/requester";
import InsumoForm from "../components/insumos/InsumoForm.vue";
import CustomDialog from "../components/CustomDialog.vue";
import InsumoDetalhes from "../components/insumos/InsumoDetalhes.vue";

import { InsumoCreateFormData, Insumo } from "../../../back/src/types/formData";
export default Vue.extend({
  name: "Insumos",
  components: {
    InsumoForm,
    CustomDialog,
    InsumoDetalhes,
  },
  data() {
    return {
      loading: false,
      header: [
        {
          text: "CAS",
          value: "cas",
        },
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "Detalhes",
          value: "detalhes",
          align: "center",
        },
      ],
      insumos: [] as Insumo[],
      insumoDetalhes: null,
      search: null,
      dialog: {
        criar: false,
        detalhes: false,
      },
    };
  },
  mounted() {
    this.carregaDados();
  },
  methods: {
    async carregaDados() {
      this.loading = true;
      try {
        this.insumos = await Requester.insumo.private.recuperaInsumos();
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    fecharDetalhes() {
      this.dialog.detalhes = false;
      this.insumoDetalhes = null;
    },
    adicionarInsumo(insumo: InsumoCreateFormData) {
      this.insumos.push(insumo);
    },
    abreDetalhes(insumo: Insumo) {
      this.dialog.detalhes = true;
      this.insumoDetalhes = insumo;
    },
  },
});
