var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itensComputed,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-end"},[_c('ExportarDadosBtn',{attrs:{"dados":_vm.itensComputed,"loading":_vm.loading}})],1)]},proxy:true},{key:"item.saldo_anterior",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.saldo_anterior) + " " + (item.unidade)))+" ")]}},{key:"item.compras",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"success--text"},[_vm._v(" "+_vm._s(((item.compras) + " " + (item.unidade)))+" ")])]}},{key:"item.emprestimos_in",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"success--text"},[_vm._v(" "+_vm._s(((item.emprestimos_in) + " " + (item.unidade)))+" ")])]}},{key:"item.consumo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"error--text"},[_vm._v(" "+_vm._s(((item.consumo) + " " + (item.unidade)))+" ")])]}},{key:"item.emprestimos_out",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"error--text"},[_vm._v(" "+_vm._s(((item.emprestimos_out) + " " + (item.unidade)))+" ")])]}},{key:"item.saldo_atual",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(((_vm.saldoAtual(item)) + " " + (item.unidade)))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }