<template>
  <div>
    <Titulo
      titulo="Produtos cadastrados"
      icone="resistor"
      descricao="Consulte, edite e exclua produtos dos laboratório"
    />
    <v-card color="basil" flat>
      <v-card-text>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="estoque"
          class="mt-2"
        >
          <template #top>
            <v-grid>
              <v-row>
                <v-col cols="11">
                  <v-text-field
                    v-model="search"
                    placeholder="Buscar ..."
                    append-icon="mdi-magnify"
                    dense
                    rounded
                    filled
                    hide-details
                  />
                </v-col>
                <v-col cols="1">
                  <v-btn color="primary">Novo</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :items="categorias"
                    item-text="nome"
                    label="Categoria"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    :items="categorias"
                    item-text="nome"
                    label="Sub-categoria"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-grid>
          </template>
          <template v-slot:item.saida>
            <v-btn color="primary">
              <v-icon small>mdi-arrow-collapse-down</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.adicionar>
            <v-btn color="primary">
              <v-icon small>mdi-arrow-collapse-up</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.acoes>
            <v-btn color="primary">
              <v-icon small>mdi-chart-bar-stacked</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Titulo from "../components/Titulo.vue";
export default {
  name: "Produtos",
  components: {
    Titulo,
  },
  computed: {
    headers() {
      return [
        {
          text: "CAS",
          value: "cas",
        },
        {
          text: "Produto",
          value: "nome",
        },
        {
          text: "Unidade",
          value: "unidade",
        },
        {
          text: "Editar",
          value: "adicionar",
        },
        {
          text: "Detalhes",
          value: "acoes",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
