













import Vue from "vue";

import ToastSnackbar from "@/components/ToastSnackbar.vue";
import LayoutLogin from "@/layouts/LayoutLogin.vue";
import LayoutPrincipal from "@/layouts/LayoutPrincipal.vue";

export default Vue.extend({
  name: "App",
  components: {
    ToastSnackbar,
    LayoutLogin,
    LayoutPrincipal,
  },
  data: () => ({
    loading: {
      logout: false,
    },
  }),
  computed: {
    currentLayout() {
      return `Layout${this.$route.meta.layout || "Principal"}`;
    },
    isRouterLoaded() {
      return this.$route.name !== null;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  // methods: {
  //   errorHandler(err: any) {
  //     console.log("Erro:", err);
  //     this.$root.$emit(
  //       "toast-error",
  //       err?.response?.data?.message ?? err?.response?.data ?? err?.message,
  //     );
  //     if (err?.response) {
  //       if (err.response?.status === 401 && this.$route.name !== "Login") {
  //         this.$store.dispatch("logout");
  //         this.$router.push({ name: "Login" });
  //       }
  //     }
  //   },
  // },
});
