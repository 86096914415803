

































































































import Vue from "vue";

import _ from "lodash";
import Requester from "@/requester";
import CustomDialog from "../../components/CustomDialog.vue";
import ProdutoSaidaForm from "../../components/saidas/ProdutoSaidaForm.vue";
import InsumoHistorico from "../../components/insumos/InsumoHistorico.vue";
import {
  ProdutoEstoque,
  Quantidade,
} from "../../../../back/src/types/formData";

export default Vue.extend({
  name: "LaboratoriosVer",
  components: {
    CustomDialog,
    ProdutoSaidaForm,
    InsumoHistorico,
  },
  data() {
    return {
      search: null,
      tab: null,
      estoque: [] as ProdutoEstoque[],
      laboratorio: {},
      entradasTeste: [],
      produtoEstoqueSelecionado: null,
      dialog: {
        saida: false,
        historico: false,
      },
    };
  },
  mounted() {
    this.carregaDados();
  },
  computed: {
    headers() {
      return [
        {
          text: "CAS",
          value: "cas",
          align: "left",
        },
        {
          text: "Produto",
          value: "nome",
        },
        {
          text: "Estoque",
          value: "quantidade",
          align: "left",
        },

        {
          text: "Ações",
          value: "acoes",
          align: "center",
          sortable: false,
        },
      ];
    },
    user() {
      return this.$store.getters.user;
    },
    estoqueSomado() {
      return _.map(this.estoque, (el: ProdutoEstoque) => ({
        ...el,
        quantidades: [...(el.quantidades as Quantidade[])],
        quantidade_total: _.sumBy(el.quantidades, "total"),
      }));
    },
  },
  methods: {
    novasSaidasCriadas() {
      this.fechaDialogSaida();
      this.carregaDados();
    },
    fechaDialogSaida() {
      this.produtoEstoqueSelecionado = null;
      this.dialog.saida = false;
    },
    verHistorico(produtoEstoque: ProdutoEstoque) {
      this.produtoEstoqueSelecionado = produtoEstoque;
      this.dialog.historico = true;
    },
    darSaida(produtoEstoque: ProdutoEstoque) {
      this.produtoEstoqueSelecionado = produtoEstoque;
      this.dialog.saida = true;
    },
    async carregaDados() {
      //this.loading.carregaDados = true;
      try {
        this.estoque = await Requester.laboratorio.private.recuperaEstoque(
          this.user.laboratorio_id,
        );
        this.laboratorio = await Requester.laboratorio.private.recuperaLaboratorioPorId(
          this.user.laboratorio_id,
        );
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        //this.loading.carregaDados = false;
      }
    },
  },
});
