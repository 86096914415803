











































































































































































import Vue from "vue";

export default Vue.extend({
  name: "Home",
  data: () => ({
    anos: [
      {
        color: "primary",
        ano: "2020",
        text: "Idealização do projeto",
      },
      {
        color: "success",
        ano: "2021",
        text:
          "Premiação em 1º lugar pelo edital 'Carnathon' e início do desenvolvimento da plataforma com a Kemio Code Software",
      },
      {
        color: "warning",
        ano: "2022",
        text:
          "Premiação no edital 'Ideias para mudar o mundo', atualização de funcionalidades",
      },
      {
        color: "error",
        ano: "2023",
        text:
          "Conclusão do projeto piloto da plataforma e abertura para os primeiros usuários",
      },
    ],
  }),
});
