



































import PedidosTabDestino from "@/components/pedidos/PedidosTabDestino.vue";
import PedidosTabOrigem from "@/components/pedidos/PedidosTabOrigem.vue";
import requester from "@/requester";
import Vue from "vue";
export default Vue.extend({
  components: { PedidosTabDestino, PedidosTabOrigem },
  name: "LaboratorioPedidosIndex",
  data: () => ({
    loading: {
      pedidosDestino: true,
      pedidosOrigem: true,
    },
    pedidosDestino: [],
    pedidosOrigem: [],
  }),
  computed: {
    laboratorioId() {
      return this.$store.getters.user?.laboratorio_id || 0;
    },
  },
  mounted() {
    this.listaPedidosDestino();
    this.listaPedidosOrigem();
  },
  methods: {
    async listaPedidosDestino() {
      this.loading.pedidosDestino = true;
      try {
        this.pedidosDestino = await requester.laboratorio.private.buscaPedidosDestino(
          this.laboratorioId,
        );
      } catch (err) {
        this.$root.$emit("toastError", err.response?.data ?? err.toString());
      } finally {
        this.loading.pedidosDestino = false;
      }
    },
    async listaPedidosOrigem() {
      this.loading.pedidosOrigem = true;
      try {
        this.pedidosOrigem = await requester.laboratorio.private.buscaPedidosOrigem(
          this.laboratorioId,
        );
      } catch (err) {
        this.$root.$emit("toastError", err.response?.data ?? err.toString());
      } finally {
        this.loading.pedidosOrigem = false;
      }
    },
  },
});
