/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

export const EMBALAGEM_TROCA_ID = 1;

export enum PedidoStatusEnum {
  PENDENTE = 'PENDENTE',
  REPROVADO = 'REPROVADO',
  APROVADO = 'APROVADO',
  RETIRADO = 'RETIRADO',
  RECEBIDO = 'RECEBIDO',
}

export enum PapeisEnum {
 ADMINISTRADOR = 1,
 TECNICO = 2,
 ALUNO = 3,
}
