import axios from "axios";
import {
  RecoveryFormData,
  TokenFormData,
  TokenSenhaAlterarFormData,
  UserApproveFormData,
  UserAtualizaEmailFormData,
  UserCreateFormData,
  UserEditFormData,
} from "../../../back/src/types/formData";
import { User } from "../../../back/src/models/User";
import { Papel } from "../../../back/src/models/Papel";

// public

const createUser = async (formData: UserCreateFormData): Promise<User> => {
  const res = await axios.post<User>("public/users", formData);
  return res.data;
};

const activeUser = async (formData: TokenFormData): Promise<void> => {
  await axios.post<User>("public/users/active", formData);
};

const updateUserEmail = async (formData: TokenFormData): Promise<void> => {
  await axios.post<User>("public/users/email", formData);
};

// private

const deleteUser = async (userId: number): Promise<User> => {
  const res = await axios.delete<User>(`users/${userId}`);
  return res.data;
};

const editUser = async (
  userId: number,
  formData: UserEditFormData,
): Promise<User> => {
  const res = await axios.put<User>(`users/${userId}`, formData);
  return res.data;
};

const AdminEditUser = async (
  userId: number,
  formData: UserEditFormData,
): Promise<User> => {
  const res = await axios.put<User>(`users/profile/${userId}`, formData);
  return res.data;
};

const editEmail = async (
  userId: number,
  formData: UserAtualizaEmailFormData,
): Promise<string> => {
  const res = await axios.put<string>(`users/email/${userId}`, formData);
  return res.data;
};

const getUsers = async (): Promise<User[]> => {
  const res = await axios.get<User[]>(`users/`);
  return res.data;
};

const getUsersPending = async (): Promise<User[]> => {
  const res = await axios.get<User[]>(`users/pending`);
  return res.data;
};

const getUsersActivated = async (): Promise<User[]> => {
  const res = await axios.get<User[]>(`users/activated`);
  return res.data;
};

const aprovarCadastro = async (
  userId: number,
  formData: UserApproveFormData,
): Promise<User> => {
  const res = await axios.post<User>(`users/approve/${userId}`, formData);
  return res.data;
};

const buscaPepeis = async (): Promise<Papel[]> => {
  const res = await axios.get<Papel[]>("public/users/papeis");
  return res.data;
};

const recuperaSenha = async (formData: RecoveryFormData): Promise<void> => {
  await axios.post<void>("public/users/recovery", formData);
};

const recuperaSenhaPorToken = async (
  formData: TokenSenhaAlterarFormData,
): Promise<void> => {
  await axios.post<void>("public/users/password", formData);
};

export default {
  public: {
    createUser,
    buscaPepeis,
    activeUser,
    recuperaSenha,
    recuperaSenhaPorToken,
    updateUserEmail,
  },
  private: {
    editEmail,
    deleteUser,
    editUser,
    getUsers,
    getUsersPending,
    getUsersActivated,
    aprovarCadastro,
    AdminEditUser,
  },
};
