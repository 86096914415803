












import Vue from "vue";
import { Insumo } from "../../../../back/src/models/Insumo";
import CustomColInfo from "../CustomColInfo.vue";

export default Vue.extend({
  name: "InsumoDetalhes",
  components: {
    CustomColInfo,
  },
  props: {
    insumo: {
      type: Object as () => Insumo,
      required: true,
    },
  },
  data() {
    return {
      dadosHistorico: null,
    };
  },
  computed: {
    insumoInfo() {
      if (!this.insumo) return [];
      return [
        {
          title: "CAS",
          content: this.insumo.cas,
          cols: 12,
        },
        {
          title: "Nome",
          content: this.insumo.nome,
          cols: 12,
        },
        {
          title: "Nome em inglês",
          content: this.insumo.nome_en,
          cols: 12,
        },
        {
          title: "Unidade",
          content: this.insumo.unidade
            ? `${this.insumo.unidade.nome} (${this.insumo.unidade.sigla})`
            : null,
          cols: 12,
        },
      ];
    },
  },
});
