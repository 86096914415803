


















import Vue from "vue";

export default Vue.extend({
  name: "CustomFormActions",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    textoConfirmar: {
      type: String,
      default: "Salvar",
    },
  },
});
