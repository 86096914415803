import axios from "axios";
import { Insumo } from "../../../back/src/models/Insumo";
import { ViewEstoquePublico } from "../../../back/src/models/ViewEstoquePublico";
import { InsumoCreateFormData } from "../../../back/src/types/formData";

const recuperaInsumos = async (): Promise<Insumo[]> => {
  const res = await axios.get<Insumo[]>("/insumos");
  return res.data;
};

const buscaEstoque = async (
  insumoId: number,
): Promise<ViewEstoquePublico[]> => {
  const res = await axios.get<ViewEstoquePublico[]>(
    `/insumos/${insumoId}/estoque`,
  );
  return res.data;
};

const criaInsumo = async (formData: InsumoCreateFormData): Promise<Insumo> => {
  const res = await axios.post<Insumo>("insumos", formData);
  return res.data;
};

export default {
  private: {
    recuperaInsumos,
    buscaEstoque,
    criaInsumo,
  },
};
