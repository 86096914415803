var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PedidosDialogDetalhes',{attrs:{"pedidoId":_vm.pedidoDetalhesId},model:{value:(_vm.dialogDetalhes),callback:function ($$v) {_vm.dialogDetalhes=$$v},expression:"dialogDetalhes"}}),_c('PedidosDialogResponder',{attrs:{"pedidoId":_vm.pedidoResponderId},on:{"pedido-alterado":function($event){return _vm.onPedidoAlterado($event)}},model:{value:(_vm.dialogResponder),callback:function ($$v) {_vm.dialogResponder=$$v},expression:"dialogResponder"}}),_c('PedidosDialogRetirar',{attrs:{"pedidoId":_vm.pedidoRetirarId},on:{"pedido-alterado":function($event){return _vm.onPedidoAlterado($event)}},model:{value:(_vm.dialogRetirar),callback:function ($$v) {_vm.dialogRetirar=$$v},expression:"dialogRetirar"}}),_c('div',{staticClass:"d-flex align-center my-3"},[_c('v-text-field',{attrs:{"placeholder":"Buscar","filled":"","rounded":"","dense":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticClass:"ml-sm-2",staticStyle:{"max-width":"200px"},attrs:{"label":"Status","items":_vm.itemsPedidoStatus,"outlined":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"search":_vm.search,"items":_vm.pedidosFiltrados,"headers":_vm.headers,"loading":_vm.loading,"must-sort":"","sort-by":"created","sort-desc":""},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created))+" ")]}},{key:"item.insumo_nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" ["+_vm._s(item.insumo_cas)+"] "+_vm._s(item.insumo_nome)+" ")]}},{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantidade)+" "+_vm._s(item.insumo_unidade_sigla)+" ")]}},{key:"item.pedido_status_atual_status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:((_vm.getPedidoStatusColor(
            item.pedido_status_atual_status
          )) + "--text font-weight-medium")},[_vm._v(" "+_vm._s(_vm.getPedidoStatusText(item.pedido_status_atual_status))+" ")])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantidade)+" "+_vm._s(item.insumo_unidade_sigla)+" ")]}},{key:"item.acao",fn:function(ref){
          var item = ref.item;
return [(_vm.podeResponder(item))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.onResponder(item.id)}}},on),[_vm._v(" mdi-thumbs-up-down ")])]}}],null,true)},[_vm._v(" Responder ")]):(_vm.podeRetirar(item))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"},on:{"click":function($event){return _vm.onRetirar(item.id)}}},on),[_vm._v(" mdi-inbox-arrow-up ")])]}}],null,true)},[_vm._v(" Retirar ")]):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"grey lighten-2"}},on),[_vm._v("mdi-minus")])]}}],null,true)},[_vm._v(" Nenhuma ação possível ")])]}},{key:"item.detalhes",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){return _vm.onDetalhes(item.id)}}},[_vm._v(" mdi-information ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }