















































































































import Vue from "vue";
import { map } from "lodash";

import { getPedidoStatusColor, getPedidoStatusText } from "@/utils/functions";

import { Pedido } from "../../../../back/src/models/Pedido";
import { pedidoStatusText } from "@/utils/constants";
import { ViewPedidoResumo } from "../../../../back/src/models/ViewPedidoResumo";
import PedidosDialogRetirar from "./PedidosDialogRetirar.vue";
import PedidosDialogResponder from "./PedidosDialogResponder.vue";
import PedidosDialogDetalhes from "./PedidosDialogDetalhes.vue";
import { PedidoStatusEnum } from "../../../../back/src/types/constants";

export default Vue.extend({
  name: "PedidosTabOrigem",
  components: {
    PedidosDialogRetirar,
    PedidosDialogResponder,
    PedidosDialogDetalhes,
  },
  props: {
    pedidos: {
      type: Array as () => Pedido[],
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialogDetalhes: false,
    dialogResponder: false,
    dialogRetirar: false,
    pedidoDetalhesId: null,
    pedidoResponderId: null,
    pedidoRetirarId: null,
    search: "",
    filterStatus: null,
    headers: [
      { value: "created", text: "Data" },
      { value: "insumo_nome", text: "Insumo" },
      { value: "quantidade", text: "Quantidade" },
      { value: "pedido_status_atual_status", text: "Status" },
      { value: "laboratorio_destino_nome", text: "Laboratório solicitante" },
      {
        value: "acao",
        text: "Ação",
        sortable: false,
        filterable: false,
        align: "center",
      },
      {
        value: "detalhes",
        text: "Detalhes",
        sortable: false,
        filterable: false,
        align: "center",
      },
    ],
  }),
  computed: {
    pedidosFiltrados() {
      if (!this.filterStatus) return this.pedidos;
      return this.pedidos.filter(
        (el: ViewPedidoResumo) =>
          el.pedido_status_atual_status === this.filterStatus,
      );
    },
    itemsPedidoStatus() {
      return map(pedidoStatusText, (text, value) => ({ text, value }));
    },
  },
  methods: {
    getPedidoStatusText,
    getPedidoStatusColor,
    podeResponder(pedido: ViewPedidoResumo) {
      return [PedidoStatusEnum.PENDENTE].includes(
        pedido?.pedido_status_atual_status,
      );
    },
    podeRetirar(pedido: ViewPedidoResumo) {
      return [PedidoStatusEnum.APROVADO].includes(
        pedido?.pedido_status_atual_status,
      );
    },
    onDetalhes(pedidoId: number) {
      this.pedidoDetalhesId = pedidoId;
      this.dialogDetalhes = true;
    },
    onResponder(pedidoId: number) {
      this.pedidoResponderId = pedidoId;
      this.dialogResponder = true;
    },
    onRetirar(pedidoId: number) {
      this.pedidoRetirarId = pedidoId;
      this.dialogRetirar = true;
    },
    onPedidoAlterado(pedido: Pedido) {
      this.dialogResponder = false;
      this.dialogRetirar = false;
      this.$emit("pedido-alterado", pedido);
    },
  },
});
