











































































import Requester from "@/requester";
import FormLaboratorio from "../../components/FormLaboratorio.vue";
import CustomDialog from "../../components/CustomDialog.vue";
import _ from "lodash";
import { Laboratorio } from "../../../../back/src/models/Laboratorio";
export default {
  name: "LaboratoriosIndex",
  components: {
    FormLaboratorio,
    CustomDialog,
  },
  data(): unknown {
    return {
      loading: false,
      header: [
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "Telefone",
          value: "telefone",
        },
        {
          text: "E-mail",
          value: "email",
        },
        {
          text: "Endereço",
          value: "endereco",
        },
        {
          text: "Editar",
          value: "editar",
        },
      ],
      laboratorios: [],
      laboratorio: null,
      search: "",
      dialog: false,
    };
  },
  mounted(): void {
    this.carregaDados();
  },
  methods: {
    async carregaDados(): Promise<void> {
      this.loading = true;
      try {
        this.laboratorios = await Requester.laboratorio.public.recuperaLaboratorios();
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    adicionaLaboratorio(laboratorio: Laboratorio): void {
      this.laboratorios.push(laboratorio);
      this.fechaDialog();
    },
    atualizaLaboratorio(laboratorio: Laboratorio): void {
      const index = _.findIndex(this.laboratorios, { id: laboratorio.id });
      if (index >= 0) {
        this.$set(this.laboratorios, index, laboratorio);
      }
      this.fechaDialog();
    },
    alterarLab(laboratorio: Laboratorio): void {
      this.laboratorio = laboratorio;
      this.dialog = true;
    },
    criarLab(): void {
      this.laboratorio = null;
      this.dialog = true;
    },
    fechaDialog(): void {
      this.laboratorio = null;
      this.dialog = false;
    },
  },
};
