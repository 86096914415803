import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import LaboratoriosIndex from "@/pages/laboratorios/LaboratoriosIndex.vue";
import LaboratoriosVer from "@/pages/laboratorios/LaboratoriosVer.vue";
import LaboratorioFornecedoresIndex from "@/pages/laboratorios/LaboratorioFornecedoresIndex.vue";
import LaboratorioPedidosIndex from "@/pages/laboratorios/LaboratorioPedidosIndex.vue";
import RelatoriosIndex from "@/pages/laboratorios/RelatoriosIndex.vue";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/laboratorios",
    name: "LaboratoriosIndex",
    component: LaboratoriosIndex,
    meta: {
      layout: "Principal",
      title: "Laboratorios",
      icon: "mdi-atom",
      public: false,
    },
  },
  {
    path: "/relatorios",
    name: "RelatoriosIndex",
    component: RelatoriosIndex,
    meta: {
      layout: "Principal",
      title: "Relatórios",
      icon: "mdi-table-large",
      public: false,
    },
  },
  {
    path: "/meu_laboratorio",
    name: "LaboratoriosVer",
    component: LaboratoriosVer,
    meta: {
      layout: "Principal",
      title: "Meu Laboratório",
      icon: "mdi-home",
      public: false,
    },
  },
  {
    path: "/fornecedores",
    name: "LaboratorioFornecedoresIndex",
    component: LaboratorioFornecedoresIndex,
    meta: {
      layout: "Principal",
      title: "Meus Fornecedores",
      icon: "mdi-truck",
      public: false,
    },
  },
  {
    path: "/pedidos",
    name: "LaboratorioPedidosIndex",
    component: LaboratorioPedidosIndex,
    meta: {
      layout: "Principal",
      title: "Pedidos",
      icon: "mdi-truck",
      public: false,
    },
  },
];

export default routes;
