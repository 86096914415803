


















































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";

import Requester from "@/requester";
import RelatorioDatatable from "../../components/relatorios/RelatorioDatatable.vue";
import { rulesRequired } from "@/utils/rules";

export default Vue.extend({
  name: "RelatoriosIndex",
  components: {
    RelatorioDatatable,
  },
  data: () => ({
    dialog: false,
    loading: false,
    form: {
      mes: null,
      laboratorioId: 0,
    },
    dados: {
      saldo: [],
      entradas: [],
      saidas: [],
    },
    laboratorios: [],
    produtos: [],
    rules: {
      required: rulesRequired,
    },
  }),
  async mounted() {
    const hoje = new Date().toISOString();
    this.form.mes = hoje.substring(0, 7);
    this.laboratorios = await Requester.laboratorio.public.recuperaLaboratorios();
    this.produtos = await Requester.insumo.private.recuperaInsumos();
    this.carregaRelatorio();
  },
  computed: {
    laboratoriosOptions() {
      return [
        {
          nome: "-Todos os laboratórios-",
          id: 0,
        },
        ...this.laboratorios.map((lab: any) => ({
          nome: lab.nome,
          id: lab.id,
        })),
      ];
    },
  },
  methods: {
    async carregaRelatorio() {
      this.loading = true;
      try {
        this.dados.saldo = await Requester.relatorio.private.saldoMesAnterior(
          this.form.laboratorioId,
          this.form.mes,
        );
        const entradasSaidas: any = await Requester.relatorio.private.entradasSaidasMes(
          this.form.laboratorioId,
          this.form.mes,
        );
        this.dados.entradas = entradasSaidas.entradas;
        this.dados.saidas = entradasSaidas.saidas;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "form.mes": {
      handler() {
        this.carregaRelatorio();
      },
    },
    "form.laboratorioId": {
      handler() {
        this.carregaRelatorio();
      },
    },
  },
});
