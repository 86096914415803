












































































































































































import Vue from "vue";

import InsumoAlertNomeCas from "../insumos/InsumoAlertNomeCas.vue";
import Requester from "@/requester";
import _ from "lodash";
import { rulesRequired } from "../../utils/rules";
import {
  ProdutoEstoque,
  SaidaFormData,
} from "../../../../back/src/types/formData";
import CustomColInfo from "../CustomColInfo.vue";
import { TiposBaixasEnum } from "../../utils/constants";

const tiposSaida = {
  EMB: "embalagem",
  UNI: "unidade",
};

export default Vue.extend({
  name: "ProdutoSaidaForm",
  props: {
    laboratorioId: {
      type: Number,
      required: true,
    },
    produtoEstoque: {
      type: Object as () => ProdutoEstoque,
      default: null,
      required: true,
    },
  },
  components: {
    InsumoAlertNomeCas,
    CustomColInfo,
  },
  data() {
    return {
      TiposBaixasEnum,
      tipoBaixa: null,
      step: 1,
      tiposSaida,
      loading: false,
      loteSelecionado: null,
      form: {
        laboratorio_destino_id: null,
        quantidade: null,
      },
      rules: {
        required: rulesRequired,
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Lote",
          value: "lote",
        },
        {
          text: "Validade",
          value: "data_validade",
        },
        {
          text: "Fornecedor",
          value: "fornecedor_nome",
        },
        {
          text: "Qtde. disponível",
          value: "total",
          align: "center",
        },
        {
          text: "Selecionar",
          value: "botao",
          align: "center",
          sortable: false,
        },
      ];
    },
    quantidadeRestante() {
      if (!this.loteSelecionado) return 0;
      return this.loteSelecionado.total - (this.form.quantidade || 0);
    },
    titulo() {
      switch (this.step) {
        case 1:
          return "Selecione o lote";
        case 2:
          return "Selecione o tipo de retirada";
        default:
          return "";
      }
    },
    unidadeIcon() {
      return this.produtoEstoque.unidade === "kg"
        ? "mdi-weight-kilogram"
        : this.produtoEstoque.unidade === "L"
        ? "mdi-cup-water"
        : "mdi-help";
    },
    formSaidaProdutoEstoque(): Vue & { validate: () => boolean } {
      return this.$refs.formSaidaProdutoEstoque as Vue & {
        validate: () => boolean;
      };
    },
  },
  methods: {
    voltar() {
      this.step = 1;
      this.tipoBaixa = null;
      this.form.quantidade = null;
    },
    submitDebounce: _.debounce(async function () {
      await this.submit();
    }, 200),
    async submit() {
      if (!this.formSaidaProdutoEstoque.validate()) {
        return;
      }
      this.loading = true;
      this.form.laboratorio_destino_id =
        this.tipoBaixa === TiposBaixasEnum.CONSUMO ? this.laboratorioId : null;
      const formData: SaidaFormData = {
        entrada_id: this.loteSelecionado.entrada_id,
        quantidade: this.form.quantidade,
        laboratorio_destino_id: this.form.laboratorio_destino_id,
      };
      try {
        await Requester.laboratorio.private.saida(this.laboratorioId, formData);
        this.$root.$emit("showToast", {
          text: "Saídas realizadas com sucesso!",
          color: "success",
        });
        this.$emit("saidas-criadas");
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
});
