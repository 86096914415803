








































/* eslint-disable @typescript-eslint/no-explicit-any */
import { DadosRelatorio, EMBALAGEM_TROCA_ID } from "@/utils/constants";
import ExportarDadosBtn from "./ExportarDadosBtn.vue";
import Vue from "vue";

export default Vue.extend({
  name: "RelatorioDatatable",
  props: {
    laboratorioId: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    produtos: {
      type: Array,
      default: () => [],
    },
    dados: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    ExportarDadosBtn,
  },
  data: () => ({}),
  computed: {
    headers() {
      return [
        {
          text: "CAS",
          value: "cas",
          align: "left",
          sortable: true,
        },
        {
          text: "Nome",
          value: "nome",
          align: "left",
          sortable: true,
        },
        {
          text: "Saldo mês ant.",
          value: "saldo_anterior",
          align: "right",
          sortable: true,
        },
        {
          text: "Compras",
          value: "compras",
          align: "right",
          sortable: true,
        },
        {
          text: "Empréstimos recebidos",
          value: "emprestimos_in",
          align: "right",
          sortable: true,
        },
        {
          text: "Consumo/Descarte",
          value: "consumo",
          align: "right",
          sortable: true,
        },
        {
          text: "Empréstimos feitos",
          value: "emprestimos_out",
          align: "right",
          sortable: true,
        },
        {
          text: "Saldo final",
          value: "saldo_atual",
          align: "right",
          sortable: true,
        },
      ];
    },
    itensComputed() {
      const comp: DadosRelatorio[] = this.produtos.map((p: any) => {
        const prod = this.dados.saldo.find((pr: any) => pr.id === p.id);
        return {
          cas: p.cas,
          nome: p.nome,
          unidade: p.unidade.sigla,
          saldo_anterior: prod ? this.getSaldoDeProduto(prod.quantidades) : 0,
          compras: this.getComprasDeProduto(p.id, this.dados.entradas),
          emprestimos_in: this.getEntradaTrocaDeProduto(
            p.id,
            this.dados.entradas,
          ),
          consumo: this.getConsumoDescarteDeProduto(p.id, this.dados.saidas),
          emprestimos_out: this.getSaidaTrocaDeProduto(p.id, this.dados.saidas),
        };
      });
      return comp.filter(
        (item: DadosRelatorio) =>
          item.saldo_anterior > 0 ||
          item.compras > 0 ||
          item.emprestimos_in > 0 ||
          item.consumo > 0 ||
          item.emprestimos_out > 0,
      );
    },
  },
  methods: {
    getSaldoDeProduto(quantidades: any[]) {
      return quantidades.reduce((prev: any, next: any) => next.total + prev, 0);
    },
    getComprasDeProduto(produtoId: number, entradas: any[]) {
      // retorna valor total apenas para COMPRAS
      return entradas
        .filter(
          (en) =>
            en.insumo_id === produtoId &&
            en.embalagem_id !== EMBALAGEM_TROCA_ID,
        )
        .reduce((sum, item) => item.total + sum, 0);
    },
    getEntradaTrocaDeProduto(produtoId: number, entradas: any[]) {
      // retorna valor total apenas para emprestimos recebidos
      return entradas
        .filter(
          (en) =>
            en.insumo_id === produtoId && en.embalagem_id == EMBALAGEM_TROCA_ID,
        )
        .reduce((sum, item) => item.total + sum, 0);
    },
    getConsumoDescarteDeProduto(produtoId: number, saidas: any[]) {
      // retorna valor total para consumos e descartes (não trocas)
      return saidas
        .filter(
          (sa) =>
            sa.entrada.insumo_id === produtoId &&
            (sa.laboratorio_destino_id === null ||
              sa.entrada.laboratorio_id === sa.laboratorio_destino_id),
        )
        .reduce((sum, item) => item.quantidade + sum, 0);
    },
    getSaidaTrocaDeProduto(produtoId: number, saidas: any[]) {
      // retorna valor total para saídas nas trocas (doações/empréstimos)
      return saidas
        .filter(
          (sa) =>
            sa.entrada.insumo_id === produtoId &&
            sa.laboratorio_destino_id !== null &&
            sa.entrada.laboratorio_id !== sa.laboratorio_destino_id,
        )
        .reduce((sum, item) => item.quantidade + sum, 0);
    },
    saldoAtual(item: DadosRelatorio) {
      return (
        item.saldo_anterior +
        item.compras +
        item.emprestimos_in -
        item.consumo -
        item.emprestimos_out
      );
    },
  },
});
