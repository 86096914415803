
















import Vue from "vue";
import { Fornecedor } from "../../../../back/src/models/Fornecedor";

import CustomColInfo from "../CustomColInfo.vue";
export default Vue.extend({
  name: "FornecedoresCardInfo",
  props: {
    fornecedor: {
      type: Object as () => Fornecedor,
      required: true,
    },
  },
  components: {
    CustomColInfo,
  },
  data() {
    return {};
  },
  computed: {
    fornecedorInfo() {
      if (!this.fornecedor) return [];
      return [
        {
          title: "Nome",
          content: this.fornecedor.nome,
          cols: 6,
        },
        {
          title: "CNPJ",
          content: this.fornecedor.cnpj,
          cols: 6,
        },
        {
          title: "Telefone",
          content: this.fornecedor.telefone,
          cols: 6,
        },
        {
          title: "E-mail",
          content: this.fornecedor.telefone,
          cols: 6,
        },
        {
          title: "Informações de contato",
          content: this.fornecedor.info_contato,
          cols: 6,
        },
      ];
    },
  },
});
