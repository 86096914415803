




















































import Vue from "vue";
import _ from "lodash";
import Requester from "@/requester";
import { InsumoCreateFormData } from "../../../../back/src/types/formData";
import CustomFormActions from "../CustomFormActions.vue";

export default Vue.extend({
  name: "InsumoForm",
  components: {
    CustomFormActions,
  },
  data() {
    return {
      rules: {
        required: [(v: string) => !!v || "* Esse campo é obrigatório"],
      },
      unidades: [],
      loading: false,
      insumoForm: {} as InsumoCreateFormData,
    };
  },
  computed: {
    formNovoInsumo(): Vue & { validate: () => boolean } {
      return this.$refs.formNovoInsumo as Vue & {
        validate: () => boolean;
      };
    },
  },

  mounted() {
    this.carregaDados();
  },
  methods: {
    async carregaDados() {
      this.loading = true;
      try {
        this.unidades = await Requester.unidade.public.recuperaUnidades();
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    submitDebounce: _.debounce(async function () {
      if (!this.formNovoInsumo.validate()) {
        return;
      }
      await this.criaNovoInsumo();
    }, 200),
    async criaNovoInsumo() {
      this.loading = true;
      try {
        const novoInsumo = await Requester.insumo.private.criaInsumo(
          this.insumoForm,
        );
        this.insumoForm = {};
        this.$emit("adicionarInsumo", novoInsumo);
        this.$emit("fechar");
        this.$root.$emit("showToast", {
          text: "Insumo criado com sucesso!",
          color: "success",
        });
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
        this.insumosForm.unidade_id = {};
      }
    },
  },
});
