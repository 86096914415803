import axios from "axios";
import { Embalagem } from "../../../back/src/models/Embalagem";

const recuperaEmbalagens = async (): Promise<Embalagem[]> => {
  const res = await axios.get<Embalagem[]>("/embalagens");
  return res.data;
};

export default {
  private: {
    recuperaEmbalagens,
  },
};
