var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.produtoEstoque)?_c('div',[_c('InsumoAlertNomeCas',{attrs:{"nome":_vm.produtoEstoque.nome,"cas":_vm.produtoEstoque.cas}}),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-h5 font-weight-regular justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.titulo))]),_c('v-avatar',{staticClass:"subheading white--text",attrs:{"color":"info","size":"24"},domProps:{"textContent":_vm._s(_vm.step)}})],1),_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('v-card-text',[_c('v-data-table',{staticClass:"mb-2",attrs:{"headers":_vm.headers,"items":_vm.produtoEstoque.quantidades,"disable-pagination":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.data_validade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateOnly")(item.data_validade))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.total) + " " + (_vm.produtoEstoque.unidade)))+" ")]}},{key:"item.botao",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.loteSelecionado = item;
                  _vm.step = 2;}}},[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,true)})],1)],1),_c('v-window-item',{attrs:{"value":2}},[(_vm.loteSelecionado)?[_c('v-card-title',{staticClass:"text-h6 font-weight-regular"},[_vm._v(" Informações do lote "),_c('b',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.loteSelecionado.lote))])]),_c('v-card-text',[_c('v-row',[_c('CustomColInfo',{attrs:{"title":"Data da compra","content":_vm._f("formatDateOnly")(_vm.loteSelecionado.data_compra),"cols":4}}),_c('CustomColInfo',{attrs:{"title":"Data de validade","content":_vm._f("formatDateOnly")(_vm.loteSelecionado.data_validade),"cols":4}}),_c('CustomColInfo',{attrs:{"title":"Localização","content":_vm.loteSelecionado.localizacao,"cols":4}}),_c('CustomColInfo',{attrs:{"title":"Fornecedor","content":_vm.loteSelecionado.fornecedor_nome,"cols":4}}),_c('CustomColInfo',{attrs:{"title":"Fabricante/Marca","content":_vm.loteSelecionado.fabricante,"cols":4}})],1)],1),_c('v-divider'),_c('v-form',{ref:"formSaidaProdutoEstoque"},[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-space-around"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":!_vm.tipoBaixa || _vm.tipoBaixa === _vm.TiposBaixasEnum.CONSUMO
                      ? 'primary'
                      : 'grey'},on:{"click":function($event){_vm.tipoBaixa = _vm.TiposBaixasEnum.CONSUMO}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-test-tube")]),_vm._v(" Consumo ")],1),_c('v-btn',{staticClass:"white--text",attrs:{"color":!_vm.tipoBaixa || _vm.tipoBaixa === _vm.TiposBaixasEnum.TROCA
                      ? 'error'
                      : 'grey'},on:{"click":function($event){_vm.tipoBaixa = _vm.TiposBaixasEnum.TROCA}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Descarte ")],1)],1)]),_c('v-expand-transition',[_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.tipoBaixa),expression:"tipoBaixa"}]},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"4"}},[_vm._v(" Quantidade disponível: "+_vm._s(((_vm.loteSelecionado.total) + " " + (_vm.produtoEstoque.unidade)))+" ")]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"ml-3 mt-1",attrs:{"label":"Quantidade desejada","outlined":"","dense":"","hide-details":"","rules":_vm.rules.required},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}},model:{value:(_vm.form.quantidade),callback:function ($$v) {_vm.$set(_vm.form, "quantidade", $$v)},expression:"form.quantidade"}})],1),_c('v-col',{class:{
                      'd-flex align-center justify-end font-weight-bold': true,
                      'error--text': _vm.quantidadeRestante < 0,
                    },attrs:{"cols":"4"}},[_vm._v(" Quantidade restante: "+_vm._s((_vm.quantidadeRestante + " " + (_vm.produtoEstoque.unidade)))+" ")])],1)],1)],1)],1)]:_vm._e()],2)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":_vm.step === 1,"text":""},on:{"click":function($event){return _vm.voltar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Voltar ")],1),_c('v-spacer'),(_vm.tipoBaixa)?_c('v-btn',{attrs:{"outlined":"","disabled":_vm.quantidadeRestante < 0,"loading":_vm.loading,"color":"success"},on:{"click":function($event){return _vm.submitDebounce()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Confirmar baixa ")],1):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }