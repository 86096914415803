











































































































import Vue from "vue";
import Requester from "@/requester";
import _ from "lodash";

import CustomDialog from "../../components/CustomDialog.vue";
import FornecedoresFormCriarAlterar from "../../components/fornecedores/FornecedoresFormCriarAlterar.vue";
import FornecedoresCardInfo from "../../components/fornecedores/FornecedoresCardInfo.vue";
import { Fornecedor } from "../../../../back/src/models/Fornecedor";
export default Vue.extend({
  name: "LaboratorioFornecedoresIndex",
  components: {
    CustomDialog,
    FornecedoresFormCriarAlterar,
    FornecedoresCardInfo,
  },
  data() {
    return {
      dialog: {
        form: false,
        ver: false,
      },
      loading: false,
      fornecedores: [],
      search: null,
      fornecedorSelecionado: null,
    };
  },
  mounted() {
    this.buscarFornecedores();
  },
  computed: {
    headers() {
      return [
        {
          text: "Fornecedor",
          value: "nome",
        },
        {
          text: "CNPJ",
          value: "cnpj",
        },
        {
          text: "Telefone",
          value: "telefone",
        },
        {
          text: "E-mail",
          value: "email",
        },
        {
          text: "Ações",
          value: "acoes",
          align: "center",
          sortable: false,
        },
      ];
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async buscarFornecedores() {
      this.loading = true;
      try {
        this.fornecedores = await Requester.laboratorio.private.buscaFornecedores(
          this.user.laboratorio_id,
        );
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    fornecedorCriado(novoFornecedor: Fornecedor) {
      this.dialog.form = false;
      this.fornecedores.push(novoFornecedor);
    },
    fornecedorAlterado(fornecedorAlterado: Fornecedor) {
      this.dialog.form = false;
      const index = _.findIndex(
        this.fornecedores,
        (el: Fornecedor) => el.id === fornecedorAlterado.id,
      );
      if (index >= 0) {
        this.fornecedores.splice(index, 1, fornecedorAlterado);
      }
    },
    selecionaFornecedor(fornecedor: Fornecedor) {
      this.fornecedorSelecionado = fornecedor;
      this.dialog.ver = true;
    },
    alteraFornecedor(fornecedor: Fornecedor) {
      this.fornecedorSelecionado = fornecedor;
      this.dialog.form = true;
    },
  },
});
