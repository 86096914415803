












































































import Vue from "vue";
import Requester from "@/requester";

import { InsumoEntradaFormData } from "../../../back/src/types/formData";
import { rulesRequired } from "../utils/rules";
import ProdutoEntradaForm from "../components/entradas/ProdutoEntradaForm.vue";
import InputData from "../components/InputData.vue";

export default Vue.extend({
  name: "Entradas",
  components: {
    ProdutoEntradaForm,
    InputData,
  },
  data() {
    return {
      entrada: {
        data_compra: "",
        data_recebimento: "",
        fernecedor_id: null,
        entradas: [],
      },
      novosProdutos: [
        {
          produto_id: null,
          fabricante: null,
        },
      ],
      valid: false,
      rules: {
        rulesRequired,
      },
      embalagens: [],
      fornecedores: [],
      loading: {
        carregaDados: false,
        criaEntrada: false,
      },
    };
  },
  mounted() {
    this.carregaDados();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    laboratorioId() {
      return this.$store.getters.user?.laboratorio_id || 0;
    },
  },
  methods: {
    async carregaDados() {
      this.loading.carregaDados = true;
      try {
        this.fornecedores = await Requester.laboratorio.private.buscaFornecedores(
          this.user.laboratorio_id,
        );
        this.embalagens = await Requester.embalagem.private.recuperaEmbalagens();
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading.carregaDados = false;
      }
    },
    adicionarProduto() {
      this.novosProdutos.push({
        produto_id: null,
        fabricante: null,
      });
    },
    removerProduto(index: number) {
      this.novosProdutos.splice(index, 1);
      this.entrada.entradas.splice(index, 1);
    },
    ataulizaInformacoes(entrada: InsumoEntradaFormData) {
      this.entrada.entradas.push(entrada);
    },
    validaFormsInsumos() {
      let tudoValido = true;
      this.novosProdutos.forEach((el: any, index: number) => {
        tudoValido =
          tudoValido && this.$refs[`formEntradaInsumo${index}`][0].isValid();
      });
      return tudoValido;
    },
    async salvaEntrada() {
      // implementado dessa forma pra forçar as chamadas de validacao
      const geralValido = this.$refs.formInfoGeral.validate();
      const insumosValidos = this.validaFormsInsumos();
      if (!insumosValidos || !geralValido) {
        return;
      }
      this.loading.criaEntrada = true;
      try {
        await Requester.laboratorio.private.criaEntrada(
          this.laboratorioId,
          this.entrada,
        );
        this.$root.$emit("showToast", {
          text: "Entrada registrada com sucesso!",
          color: "success",
        });
        this.$router.push({ name: "LaboratoriosVer" });
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading.criaEntrada = false;
      }
    },
  },
});
