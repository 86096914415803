import axios from "axios";
import { AuthLoginFormData } from "../../../back/src/types/formData";
import { User } from "../../../back/src/models/User";

// public

const login = async (formData: AuthLoginFormData): Promise<User> => {
  const res = await axios.post<User>("/public/auth/login", formData);
  return res.data;
};

const logout = async (): Promise<void> => {
  await axios.post("public/auth/logout");
};

// private

const checkSession = async (): Promise<void> => {
  await axios.get("auth/check_session");
};

export default {
  public: {
    login,
    logout,
  },
  private: {
    checkSession,
  },
};
