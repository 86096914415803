















import Vue from "vue";
export default Vue.extend({
  name: "ToastSnackbar",
  data: () => ({
    show: false,
    color: "info",
    text: "",
    timeout: 3000,
  }),
  computed: {
    bottom() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    top() {
      return this.$vuetify.breakpoint.smAndUp;
    },
  },
  created() {
    this.$root.$on(
      "showToast",
      (options: { text: string; color?: string; timeout?: number }) => {
        this.showToast(options);
      },
    );
    this.$root.$on("toastError", (text: string) => {
      this.showToast({ text, color: "error", timeout: 5000 });
    });
    this.$root.$on("toastSuccess", (text: string) => {
      this.showToast({ text, color: "success" });
    });
  },
  methods: {
    showToast(options: { text: string; color?: string; timeout?: number }) {
      this.text = options.text;
      this.color = options.color ?? "info";
      this.timeout = options.timeout ?? 3000;
      this.show = true;
    },
  },
});
