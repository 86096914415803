import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/usuarios",
    name: "AdminUsuarios",
    component: () =>
      import(
        /* webpackChunkName: "usuarios" */ "@/pages/admin/AdminUsuarios.vue"
      ),
    meta: {
      layout: "Principal",
      title: "Usuários",
      icon: "mdi-account-group-outline",
      public: false,
    },
  },
];

export default routes;
