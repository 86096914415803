import { PedidoStatusEnum } from "../../../back/src/types/constants";

export const pedidoStatusColor = {
  [PedidoStatusEnum.APROVADO]: "success",
  [PedidoStatusEnum.PENDENTE]: "info",
  [PedidoStatusEnum.RECEBIDO]: "primary",
  [PedidoStatusEnum.REPROVADO]: "error",
  [PedidoStatusEnum.RETIRADO]: "warning",
};

export const pedidoStatusText = {
  [PedidoStatusEnum.APROVADO]: "Aprovado",
  [PedidoStatusEnum.PENDENTE]: "Pendente",
  [PedidoStatusEnum.RECEBIDO]: "Recebido",
  [PedidoStatusEnum.REPROVADO]: "Reprovado",
  [PedidoStatusEnum.RETIRADO]: "Retirado",
};

export enum TiposBaixasEnum {
  CONSUMO = "consumo",
  TROCA = "troca",
  DESCARTE = "descarte",
}

export interface DadosRelatorio {
  cas: string;
  nome: string;
  unidade: string;
  saldo_anterior: number;
  compras: number;
  emprestimos_in: number;
  consumo: number;
  emprestimos_out: number;
}

export const EMBALAGEM_TROCA_ID = 1;
