















































































































































































import Vue from "vue";

import requester from "@/requester";
import { rulesRequired } from "@/utils/rules";

import { Insumo } from "../../../../back/src/models/Insumo";
import { ViewEstoquePublico } from "../../../../back/src/models/ViewEstoquePublico";

import CustomDialog from "../CustomDialog.vue";
import { debounce } from "lodash";
import { PedidoCriarFormData } from "../../../../back/src/types/formData";

export default Vue.extend({
  name: "PedidosDialogCriarPedido",
  components: {
    CustomDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: {
      insumos: true,
      estoque: false,
      submitForm: false,
    },
    step: 1,
    selectedItem: null as ViewEstoquePublico | null,
    insumos: [] as Insumo[],
    estoque: [] as ViewEstoquePublico[],
    form: {
      insumoId: null,
      quantidade: null,
    },
    rules: {
      required: rulesRequired,
    },
    search: "",
    headers: [
      { value: "laboratorio_nome", text: "Laboratório" },
      { value: "laboratorio_email", text: "Email" },
      { value: "laboratorio_telefone", text: "Telefone" },
      { value: "estoque_atual", text: "Estoque atual", align: "end" },
      {
        value: "selecionar",
        text: "Selecionar",
        align: "center",
        sortable: false,
        filterable: false,
      },
    ],
  }),
  computed: {
    rulesQuantidade() {
      return [
        ...rulesRequired,
        (v: string) =>
          Number(v) > 0 || "Deve ser um número positivo maior que zero",
        (v: string) => {
          const max = this.selectedItem?.estoque_atual || 0;
          return (
            Number(v) <= max ||
            "Não pode ser maior que o estoque atual do laboratório"
          );
        },
      ];
    },
    laboratorioId() {
      return this.$store.getters.user?.laboratorio_id || 0;
    },
    itemInsumos() {
      return this.insumos.map((insumo: Insumo) => ({
        value: insumo.id,
        text: `[${insumo.cas}] ${insumo.nome}`,
      }));
    },
  },
  async mounted() {
    await this.buscaInsumos();
  },
  methods: {
    onInput(event: boolean) {
      if (!event) {
        if (this.loading.submitForm) return;
        this.step = 1;
      }
      this.$emit("input", event);
    },
    limpaForm() {
      this.form.insumoId = null;
      this.form.quantidade = null;
      this.form.mensagem = null;
      this.step = 1;
    },
    selecionaItem(item: ViewEstoquePublico) {
      if (item.laboratorio_id === this.laboratorioId) return;
      this.selectedItem = item;
      this.step = 2;
    },
    async buscaInsumos() {
      this.loading.insumos = true;
      try {
        this.insumos = await requester.insumo.private.recuperaInsumos();
      } catch (err) {
        this.$root.$emit("toastError", err?.response?.data || err.toString());
      }
      this.loading.insumos = false;
    },
    async buscaEstoqueInsumo() {
      const insumoId = this.form.insumoId;
      if (!insumoId) return;
      this.loading.estoque = true;
      try {
        this.estoque = await requester.insumo.private.buscaEstoque(insumoId);
      } catch (err) {
        this.$root.$emit("toastError", err?.response?.data || err.toString());
      }
      this.loading.estoque = false;
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs?.form?.validate()) {
        return;
      }
      this.loading.submitForm = true;
      try {
        const formData: PedidoCriarFormData = {
          laboratorio_origem_id: this.selectedItem.laboratorio_id,
          insumo_id: this.selectedItem.insumo_id,
          quantidade: Number(this.form.quantidade) || 0,
          mensagem: this.form.mensagem?.trim() || null,
        };
        const pedido = await requester.laboratorio.private.criaPedido(
          this.laboratorioId,
          formData,
        );
        this.$root.$emit("toastSuccess", "Pedido realizado com sucesso");
        this.$emit("novo-pedido", pedido);
        this.limpaForm();
      } catch (err) {
        this.$root.$emit("toastError", err?.response?.data || err.toString());
      }
      this.loading.submitForm = false;
    },
  },
});
