import "@mdi/font/css/materialdesignicons.css";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "vuetify/dist/vuetify.min.css";
import Vue from "vue";
import Vuetify from "vuetify";
import en from "vuetify/src/locale/pt";
import pt from "vuetify/src/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  lang: {
    locales: { en, pt },
    current: "en",
  },
  theme: {
    themes: {
      light: {
        // primary: "#28856d",
        // success: "#5AB035",
        // warning: "#CD733E",
        // error: "#C03A52",
        // info: "#305686",
        // secondary: "#004a60",
        primary: "#154D80",
        success: "#0D8658",
        warning: "#C67C14",
        error: "#B3123D",
        info: "#4777A2",
        secondary: "#585E5F",
        amphibiaVerde: "#00946e",

        // accent: "#94d495",
      },
    },
  },
});
