import axios from "axios";
import { Fornecedor } from "../../../back/src/models/Fornecedor";
import { Laboratorio } from "../../../back/src/models/Laboratorio";
import { Pedido } from "../../../back/src/models/Pedido";
import { ViewPedidoResumo } from "../../../back/src/models/ViewPedidoResumo";
import {
  PedidoAprovarFormData,
  PedidoReceberFormData,
  PedidoReprovarFormData,
  PedidoRetirarFormData,
  EntradasSaidas,
  FornecedorAlterarFormData,
  FornecedorCriarFormData,
  LaboratorioCreateFormData,
  PedidoCriarFormData,
  ProdutoEstoque,
  SaidaFormData,
  EntradaFormData,
} from "../../../back/src/types/formData";

const recuperaLaboratorios = async (): Promise<Laboratorio[]> => {
  const res = await axios.get<Laboratorio[]>("public/laboratorios");
  return res.data;
};

const recuperaLaboratorioPorId = async (
  laboratorioId: number,
): Promise<Laboratorio> => {
  const res = await axios.get<Laboratorio>(`laboratorio/${laboratorioId}`);
  return res.data;
};

const recuperaEntradasPorLabIdInsumoId = async (
  laboratorioId: number,
  insumoId: number,
): Promise<ProdutoEstoque[]> => {
  const res = await axios.get<ProdutoEstoque[]>(
    `estoque/${laboratorioId}/${insumoId}`,
  );
  return res.data;
};

const recuperaEstoque = async (
  laboratorioId: number,
): Promise<ProdutoEstoque[]> => {
  const res = await axios.get<ProdutoEstoque[]>(`estoque/${laboratorioId}`);
  return res.data;
};

const buscaHistoricoInsumo = async (
  laboratorioId: number,
  insumoId: number,
): Promise<EntradasSaidas> => {
  const res = await axios.get<EntradasSaidas>(
    `laboratorio/${laboratorioId}/insumo/${insumoId}`,
  );
  return res.data;
};

const criaLaboratorio = async (
  formData: LaboratorioCreateFormData,
): Promise<Laboratorio> => {
  const res = await axios.post<Laboratorio>("laboratorio", formData);
  return res.data;
};

const alteraLaboratorio = async (
  formData: LaboratorioCreateFormData,
): Promise<Laboratorio> => {
  const res = await axios.put<Laboratorio>(
    `laboratorio/${formData.id}`,
    formData,
  );
  return res.data;
};

const saida = async (
  laboratorioId: number,
  formData: SaidaFormData,
): Promise<void> => {
  const res = await axios.post<void>(
    `laboratorio/${laboratorioId}/saida`,
    formData,
  );
  return res.data;
};

const criaEntrada = async (
  laboratorioId: number,
  formData: EntradaFormData,
): Promise<void> => {
  const res = await axios.post<void>(
    `laboratorio/${laboratorioId}/entrada`,
    formData,
  );
  return res.data;
};

const buscaFornecedores = async (
  laboratorioId: number,
): Promise<Fornecedor[]> => {
  const res = await axios.get<Fornecedor[]>(
    `laboratorio/${laboratorioId}/fornecedores`,
  );
  return res.data;
};

const criarFornecedor = async (
  laboratorioId: number,
  formData: FornecedorCriarFormData,
): Promise<Fornecedor> => {
  const res = await axios.post<Fornecedor>(
    `laboratorio/${laboratorioId}/fornecedores`,
    formData,
  );
  return res.data;
};

const alterarFornecedor = async (
  laboratorioId: number,
  formData: FornecedorAlterarFormData,
): Promise<Fornecedor> => {
  const res = await axios.put<Fornecedor>(
    `laboratorio/${laboratorioId}/fornecedores`,
    formData,
  );
  return res.data;
};

const buscaPedidosOrigem = async (
  laboratorioId: number,
): Promise<ViewPedidoResumo[]> => {
  const res = await axios.get<ViewPedidoResumo[]>(
    `laboratorio/${laboratorioId}/pedidos_origem`,
  );
  return res.data;
};

const buscaPedidosDestino = async (
  laboratorioId: number,
): Promise<ViewPedidoResumo[]> => {
  const res = await axios.get<ViewPedidoResumo[]>(
    `laboratorio/${laboratorioId}/pedidos_destino`,
  );
  return res.data;
};

const criaPedido = async (
  laboratorioId: number,
  formData: PedidoCriarFormData,
): Promise<Pedido> => {
  const res = await axios.post<Pedido>(
    `laboratorio/${laboratorioId}/pedidos`,
    formData,
  );
  return res.data;
};

const buscaPedido = async (
  laboratorioId: number,
  pedidoId: number,
): Promise<Pedido> => {
  const res = await axios.get<Pedido>(
    `laboratorio/${laboratorioId}/pedidos/${pedidoId}`,
  );
  return res.data;
};

const aprovaPedido = async (
  laboratorioId: number,
  pedidoId: number,
  formData: PedidoAprovarFormData,
): Promise<Pedido> => {
  const res = await axios.put<Pedido>(
    `laboratorio/${laboratorioId}/pedidos/${pedidoId}/aprovar`,
    formData,
  );
  return res.data;
};

const reprovaPedido = async (
  laboratorioId: number,
  pedidoId: number,
  formData: PedidoReprovarFormData,
): Promise<Pedido> => {
  const res = await axios.put<Pedido>(
    `laboratorio/${laboratorioId}/pedidos/${pedidoId}/reprovar`,
    formData,
  );
  return res.data;
};

const retiraPedido = async (
  laboratorioId: number,
  pedidoId: number,
  formData: PedidoRetirarFormData,
): Promise<Pedido> => {
  const res = await axios.put<Pedido>(
    `laboratorio/${laboratorioId}/pedidos/${pedidoId}/retirar`,
    formData,
  );
  return res.data;
};

const recebePedido = async (
  laboratorioId: number,
  pedidoId: number,
  formData: PedidoReceberFormData,
): Promise<Pedido> => {
  const res = await axios.put<Pedido>(
    `laboratorio/${laboratorioId}/pedidos/${pedidoId}/receber`,
    formData,
  );
  return res.data;
};

const relatorio = async (
  laboratorioId: number,
  mes: string,
): Promise<ProdutoEstoque[]> => {
  const res = await axios.get<ProdutoEstoque[]>("relatorio", {
    params: {
      laboratorioId,
      mes,
    },
  });
  return res.data;
};

export default {
  public: {
    recuperaLaboratorios,
  },
  private: {
    alteraLaboratorio,
    alterarFornecedor,
    buscaFornecedores,
    buscaHistoricoInsumo,
    buscaPedidosDestino,
    buscaPedidosOrigem,
    criaLaboratorio,
    criaPedido,
    criarFornecedor,
    recuperaEntradasPorLabIdInsumoId,
    recuperaEstoque,
    recuperaLaboratorioPorId,
    saida,
    criaEntrada,
    buscaPedido,
    aprovaPedido,
    reprovaPedido,
    retiraPedido,
    recebePedido,
    relatorio,
  },
};
