<template>
  <div>
    <v-tabs v-model="tab" background-color="teal lighten-5" color="basil" grow>
      <v-tab href="#interno">Uso Interno</v-tab>
      <v-tab href="#externo">Troca / Doação</v-tab>
      <v-tab href="#descarte">descarte</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="interno">
        <v-card outlined elevation="6" class="mt-6">
          <v-card-title class="teal lighten-5 d-flex mb-6">
            <h5 class="text--secondary">
              <v-icon left>mdi-beaker-outline</v-icon>
              Informações dos produtos utilizados internamente
            </h5>
          </v-card-title>
          <FormSaidaUsoInterno class="mt-2" :estoque="estoque" />

          <v-footer class="teal lighten-5"></v-footer>
        </v-card>
      </v-tab-item>
      <v-tab-item value="externo">
        <v-card outlined elevation="6" class="mt-6">
          <v-card-title class="teal lighten-5 d-flex mb-6">
            <h5 class="text--secondary">
              <v-icon left>mdi-atom</v-icon>
              Laboratório de destino
            </h5>
          </v-card-title>
          <v-autocomplete
            :items="laboratorios"
            item-text="nome"
            label="Destino"
            outlined
            dense
            class="mx-2"
          ></v-autocomplete>
          <v-footer class="teal lighten-5"></v-footer>
        </v-card>
        <v-card outlined elevation="6" class="mt-6">
          <v-card-title class="teal lighten-5 d-flex mb-6">
            <h5 class="text--secondary">
              <v-icon left>mdi-beaker-outline</v-icon>
              Informações dos produtos trocados / doados
            </h5>
          </v-card-title>
          <FormSaidaUsoInterno class="mt-2" :estoque="estoque" />

          <v-footer class="teal lighten-5"></v-footer>
        </v-card>
      </v-tab-item>
      <v-tab-item value="descarte">
        <v-card outlined elevation="6" class="mt-6">
          <v-card-title class="teal lighten-5 d-flex mb-6">
            <h5 class="text--secondary">
              <v-icon left>mdi-atom-variant</v-icon>
              Informações dos produtos que serão descartados
            </h5>
          </v-card-title>
          <FormSaidaUsoInterno class="mt-2" :estoque="estoque" />

          <v-footer class="teal lighten-5"></v-footer>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-col>
      <v-row class="mt-2">
        <v-btn color="error" outlined>Cancelar</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="e6 = 3">Salvar</v-btn>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import FormSaidaUsoInterno from "../components/FormSaidaUsoInterno.vue";
import Requester from "@/requester";
export default {
  name: "Saidas",
  components: {
    FormSaidaUsoInterno,
  },

  data() {
    return {
      tab: "",
      destino: 0,
      saidas: [],
      laboratorios: [],
      estoque: [],
      loading: {
        carregaDados: false,
      },
    };
  },
  mounted() {
    this.carregaDados();
  },
  methods: {
    async carregaDados() {
      this.loading.carregaDados = true;
      try {
        this.estoque = await Requester.laboratorio.private.recuperaEstoque(
          this.user.laboratorio_id,
        );
        this.laboratorios = await Requester.laboratorio.public.recuperaLaboratorios();
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading.carregaDados = false;
      }
    },

    adicionarSaida() {
      this.saidas.push({
        destino_id: "",
        produto_id: "",
        lote_id: "",
        quantidade: "",
      });
    },
    removerSaida(index) {
      this.saidas.splice(index, 1);
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>

<style>
.opcoes {
  background-color: green;
}
</style>
