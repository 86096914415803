


































































































































import Vue from "vue";

import requester from "@/requester";

import CustomDialog from "../CustomDialog.vue";
import { debounce, last } from "lodash";
import {
  PedidoRetirarFormData,
  ProdutoEstoque,
} from "../../../../back/src/types/formData";
import { rulesRequired } from "@/utils/rules";
import { Pedido } from "../../../../back/src/models/Pedido";
import { getPedidoStatusColor, getPedidoStatusText } from "@/utils/functions";
import { PedidoStatusEnum } from "../../../../back/src/types/constants";
import PedidosInfo from "./PedidosInfo.vue";

export default Vue.extend({
  name: "PedidosDialogPróximaEtapa",
  components: {
    CustomDialog,
    PedidosInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    pedidoId: {
      type: Number || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      submitForm: false,
      estoque: false,
      pedido: false,
    },
    pedido: null as Pedido | null,
    estoque: [] as ProdutoEstoque[],
    headers: [
      { text: "Selecionar", value: "selecionar", align: "center" },
      { text: "Lote", value: "lote" },
      { text: "Fornecedor", value: "fornecedor_nome" },
      { text: "Localização", value: "localizacao" },
      { text: "Validade", value: "data_validade" },
      { text: "Quantidade atual", value: "total" },
      { text: "Retirada", value: "retirada" },
    ],
    form: {
      mensagem: null,
    },
    rules: {
      required: rulesRequired,
    },
  }),
  computed: {
    podeRetirar() {
      return [PedidoStatusEnum.APROVADO].includes(
        (last(this.pedido?.log_status) as any)?.status,
      );
    },
    retiradaTotal() {
      return this.estoque.reduce(
        (acc: number, el: any) => acc + Number(el.retirada) || 0,
        0,
      );
    },
    diffRetirada() {
      return Number(this.pedido?.quantidade || 0) - Number(this.retiradaTotal);
    },
    retiradaCorreta() {
      return Math.abs(this.diffRetirada) < Number.EPSILON;
    },
    laboratorioId() {
      return this.$store.getters.user?.laboratorio_id || 0;
    },
  },
  watch: {
    async pedidoId() {
      await this.buscaPedido();
      await this.buscaEstoque();
    },
  },
  async mounted() {
    await this.buscaPedido();
    await this.buscaEstoque();
  },
  methods: {
    getPedidoStatusText,
    getPedidoStatusColor,
    onInput(event: boolean) {
      this.$emit("input", event);
    },
    // BUSCA PEDIDO
    async buscaPedido() {
      if (!this.pedidoId) return;
      this.loading.pedido = true;
      try {
        this.pedido = await requester.laboratorio.private.buscaPedido(
          this.laboratorioId,
          this.pedidoId,
        );
      } catch (err) {
        this.$root.$emit("toastError", err?.response?.data || err.toString());
      }
      this.loading.pedido = false;
    },
    // BUSCA ESTOQUE INSUMO DO PEDIDO
    async buscaEstoque() {
      if (!this.pedido) return;
      this.loading.estoque = true;
      try {
        const estoque = await requester.laboratorio.private.recuperaEntradasPorLabIdInsumoId(
          this.pedido?.laboratorio_origem_id,
          this.pedido?.insumo_id,
        );
        this.estoque = estoque.map((el) => ({
          ...el,
          retirada: null,
          selecionar: false,
        }));
      } catch (err) {
        this.$root.$emit("toastError", err?.response?.data || err.toString());
      } finally {
        this.loading.estoque = false;
      }
    },
    // RETIRAR
    submitRetirarDebounce: debounce(async function () {
      await this.submitRetirar();
    }, 200),
    async submitRetirar() {
      if (!this.$refs?.form?.validate() || !this.retiradaCorreta) {
        return;
      }
      this.loading.submitForm = true;
      try {
        const formData: PedidoRetirarFormData = {
          mensagem: this.form?.mensagem?.trim() || null,
          retiradas: this.estoque
            .filter((el: any) => el.selecionar)
            .map((el: any) => ({
              entrada_id: el.entrada_id,
              quantidade: Number(el.retirada),
            })),
        };
        const pedido = await requester.laboratorio.private.retiraPedido(
          this.laboratorioId,
          this.pedido?.id,
          formData,
        );
        this.$root.$emit("toastSuccess", "Pedido retirado com sucesso");
        this.$emit("pedido-alterado", pedido);
        await this.buscaPedido();
        await this.buscaEstoque();
        this.form.mensagem = null;
      } catch (err) {
        this.$root.$emit("toastError", err?.response?.data || err.toString());
      }
      this.loading.submitForm = false;
    },
  },
});
