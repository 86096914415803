









import Vue from "vue";

export default Vue.extend({
  name: "InsumoAlertNomeCas",
  props: {
    nome: {
      type: String,
      required: true,
    },
    cas: {
      type: String,
      required: true,
    },
  },
});
