































































































































import Vue from "vue";
import Requester from "@/requester";

import _ from "lodash";
import { RecoveryFormData } from "../../../back/src/types/formData";
export default {
  name: "MeusDados",

  data() {
    return {
      loading: {
        submitSenha: false,
        submitEmail: false,
        submitNome: false,
      },
      editar: {
        nome: true,
        email: true,
      },

      editForm: {
        id: null,
        nome: null,
        email: null,
      },

      rules: {
        required: [(v: string) => !!v || "* Esse campo é obrigatório"],
      },
    };
  },
  mounted() {
    this.editForm.nome = this.user.nome;
    this.editForm.email = this.user.email;
    this.editForm.id = this.user.id;
  },

  computed: {
    formUserEdit(): Vue & { validate: () => boolean } {
      return this.$refs.formUserEdit as Vue & {
        validate: () => boolean;
      };
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    submitSenhaDebounce: _.debounce(async function () {
      await this.alteraSenha();
    }, 200),

    async alteraSenha() {
      this.loading.submitSenha = true;
      const formData: RecoveryFormData = {
        email: this.user.email,
      };
      try {
        await Requester.users.public.recuperaSenha(formData);
        this.$root.$emit("showToast", {
          text: "Enviamos um e-mail com instruções para alteração de senha!",
          color: "success",
        });
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading.submitSenha = false;
      }
    },

    submitEmailDebounce: _.debounce(async function () {
      await this.alteraEmail();
    }, 200),

    async alteraEmail() {
      this.loading.submitEmail = true;
      const formData = {
        id: this.user.id,
        email: this.editForm.email,
      };
      try {
        await Requester.users.private.editEmail(Number(this.user.id), formData);
        this.$root.$emit("showToast", {
          text: "Enviamos um e-mail com instruções para alteração de email!",
          color: "success",
        });
        this.editar.email = false;
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading.submitEmail = false;
        this.editar.email = true;
      }
    },

    submitNomeDebounce: _.debounce(async function () {
      await this.alteraNome();
    }, 200),

    async alteraNome() {
      this.loading.submitNome = true;
      const formData = {
        id: this.user.id,
        nome: this.editForm.nome,
      };
      try {
        await Requester.users.private.editUser(Number(this.user.id), formData);
        this.$root.$emit("showToast", {
          text: "Nome alterado com sucesso!",
          color: "success",
        });
        this.$store.dispatch("setUserName", this.editForm.nome);
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading.submitNome = false;
        this.editar.nome = true;
      }
    },
  },
};
