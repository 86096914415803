





























































import Vue from "vue";
import ProdutoEntradaLoteForm from "./ProdutoEntradaLoteForm.vue";
import Requester from "@/requester";
import _ from "lodash";
import { rulesRequired } from "../../utils/rules";

export default Vue.extend({
  name: "ProdutoEntradaForm",
  components: {
    ProdutoEntradaLoteForm,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      informacoes: {
        insumo_id: null,
        fabricante: null,
        produtos: [],
      },
      valid: false,
      rules: {
        rulesRequired,
      },
      insumos: [],
      loading: false,
    };
  },

  mounted() {
    this.carregaDados();
  },
  methods: {
    isValid() {
      const detalhesValidos = this.$refs.formDetalheInsumo.isValid();
      return this.$refs.formEntradaInsumo.validate() && detalhesValidos;
    },
    cancelarEntrada() {
      this.$emit("fechar-produto", this.index);
    },
    async carregaDados() {
      this.loading = true;
      try {
        this.insumos = await Requester.insumo.private.recuperaInsumos();
        this.$emit("atualiza-informacoes", this.informacoes);
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    atualizaProdutos(produtos: any) {
      this.informacoes.produtos = produtos;
    },
    adicionarProduto() {
      this.informacoes.produtos.push({
        lote: null,
        embalagem_id: null,
        qtde_de_embalagem: null,
        qtde_por_embalagem: null,
        total: null,
        data_validade: "",
        localizacao: null,
      });
    },
    retornaUnidade() {
      if (this.informacoes.insumo_id) {
        const insumoSelecionado = _.find(this.insumos, (insumo) => {
          return insumo.id === this.informacoes.insumo_id;
        });

        return insumoSelecionado.unidade.sigla;
      } else {
        return "";
      }
    },
  },
  computed: {
    itemsInsumos() {
      return this.insumos.map((produto: any) => {
        return {
          id: produto.id,
          nome: `[${produto.cas}] ${produto.nome}`,
        };
      });
    },
  },
});
