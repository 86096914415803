












































import Vue from "vue";
import _ from "lodash";
import Requester from "@/requester";
import { LaboratorioCreateFormData } from "../../../back/src/types/formData";
import CustomFormActions from "./CustomFormActions.vue";
import { Laboratorio } from "../../../back/src/models/Laboratorio";
import { rulesEmail } from "@/utils/rules";

export default Vue.extend({
  name: "FormLaboratorio",
  props: {
    laboratorio: {
      type: Object as () => Laboratorio,
      default: null,
    },
  },
  components: {
    CustomFormActions,
  },
  data() {
    return {
      rules: {
        required: [(v: string) => !!v || "Campo obrigatório"],
        email: rulesEmail,
      },
      loading: false,
      form: {
        id: null,
        nome: null,
        telefone: null,
        email: null,
        endereco: null,
      },
    };
  },
  computed: {
    formNovoLaboratorio(): Vue & { validate: () => boolean } {
      return this.$refs.formNovoLaboratorio as Vue & {
        validate: () => boolean;
      };
    },
  },
  mounted() {
    this.preencheForm();
  },
  methods: {
    preencheForm() {
      if (this.laboratorio) {
        this.form.id = this.laboratorio.id;
        this.form.nome = this.laboratorio.nome;
        this.form.telefone = this.laboratorio.telefone;
        this.form.email = this.laboratorio.email;
        this.form.endereco = this.laboratorio.endereco;
      } else {
        this.form.id = null;
        this.form.nome = null;
        this.form.telefone = null;
        this.form.email = null;
        this.form.endereco = null;
      }
    },
    cancelar() {
      this.$emit("fechar");
    },
    submitDebounce: _.debounce(async function () {
      if (!this.formNovoLaboratorio.validate()) {
        return;
      }
      if (this.laboratorio) {
        await this.editaLaboratorio();
      } else {
        await this.criaNovoLaboratorio();
      }
    }, 200),
    async editaLaboratorio() {
      this.loading = true;
      try {
        const laboratorioEditado = await Requester.laboratorio.private.alteraLaboratorio(
          {
            id: this.form.id,
            nome: this.form.nome,
            telefone: this.form.telefone,
            email: this.form.email,
            endereco: this.form.endereco,
          },
        );
        this.$emit("atualiza-laboratorio", laboratorioEditado);
        this.$root.$emit("showToast", {
          text: "Laboratório editado com sucesso!",
          color: "success",
        });
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },

    async criaNovoLaboratorio() {
      this.loading = true;
      try {
        const novoLaboratorio = await Requester.laboratorio.private.criaLaboratorio(
          {
            nome: this.form.nome,
            telefone: this.form.telefone,
            email: this.form.email,
            endereco: this.form.endereco,
          } as LaboratorioCreateFormData,
        );
        this.laboratorioForm = {};
        this.$emit("adiciona-laboratorio", novoLaboratorio);
        this.$root.$emit("showToast", {
          text: "Laboratório criado com sucesso!",
          color: "success",
        });
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    laboratorio() {
      this.preencheForm();
    },
  },
});
