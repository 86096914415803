






























































































































































































































import Vue from "vue";
import { orderBy, sumBy } from "lodash";
import Requester from "@/requester";

import {
  ProdutoEstoque,
  ItemHistoricoInfo,
} from "../../../../back/src/types/formData";
import InsumoAlertNomeCas from "./InsumoAlertNomeCas.vue";
import CustomColInfo from "../CustomColInfo.vue";
import { Saida } from "../../../../back/src/models/Saida";
import { Entrada } from "../../../../back/src/models/Entrada";

export default Vue.extend({
  name: "InsumoHistorico",
  components: {
    CustomColInfo,
    InsumoAlertNomeCas,
  },
  props: {
    laboratorioId: {
      type: Number,
      required: true,
    },
    produtoEstoque: {
      type: Object as () => ProdutoEstoque,
      default: null,
      required: true,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Data da compra",
          value: "data_compra",
          class: "text-left",
          sortable: true,
        },
        {
          text: "Realizado por",
          value: "user",
          class: "text-left",
          sortable: false,
        },
        {
          text: "Lote",
          value: "lote",
          class: "text-left",
          sortable: false,
        },
        {
          text: "Quantidade atual",
          value: "quantidade",
          class: "text-right",
          sortable: false,
          width: 140,
        },
        {
          text: "",
          value: "expand",
          class: "text-center",
          sortable: false,
          width: 1,
        },
      ];
    },
    headersSaidas() {
      return [
        {
          text: "Data e hora",
          value: "data_saida",
          align: "center",
          sortable: false,
        },
        {
          text: "Quantidade",
          value: "quantidade",
          align: "right",
          sortable: false,
        },
        {
          text: "Realizado por",
          value: "user.nome",
          align: "left",
          sortable: false,
        },
        {
          text: "Tipo de saída",
          value: "tipo_saida",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  data() {
    return {
      expanded: [],
      dadosHistorico: null,
      itensHistoridoOrdenados: [],
      tiposSaida: {
        CONSUMO: {
          nome: "Consumo",
          class: "warning--text",
          color: "warning",
          icon: "mdi-flask-outline",
        },
        DESCARTE: {
          nome: "Descarte",
          class: "error--text",
          color: "error",
          icon: "mdi-delete",
        },
        TROCA: {
          nome: "Troca",
          class: "primary--text",
          color: "primary",
          icon: "mdi-recycle-variant",
        },
      },
    };
  },
  mounted() {
    this.carregaDados();
  },
  methods: {
    montaItens() {
      if (!this.dadosHistorico) return;
      const itens: ItemHistoricoInfo[] = [];
      this.dadosHistorico.entradas.forEach((ent: Entrada) => {
        itens.push({
          id: ent.id,
          laboratorio_id: ent.laboratorio_id,
          total: ent.total,
          data_compra: `${ent.data_compra}`,
          lote: ent.lote,
          fabricante: ent.fabricante,
          data_recebimento: `${ent.data_recebimento}`,
          data_validade: `${ent.data_validade}`,
          localizacao: ent.localizacao,
          qtde_embalagem: ent.qtde_embalagem,
          qtde_por_embalagem: ent.qtde_por_embalagem,
          expand: false,
          tipo_embalagem: ent?.embalagem?.nome,
          unidade: this.produtoEstoque.unidade,
          user: ent?.user?.nome,
          fornecedor: ent?.fornecedor?.nome,
          saidas: orderBy(
            this.dadosHistorico.saidas.filter(
              (sd: Saida) => sd.entrada_id === ent.id,
            ),
            ["data_saida", "id"],
            ["desc", "desc"],
          ),
        } as ItemHistoricoInfo);
      });
      this.itensHistoridoOrdenados = orderBy(itens, ["data"], ["desc"]);
    },
    calculaQtdeAtual(item: ItemHistoricoInfo) {
      return item.total - sumBy(item.saidas, "quantidade");
    },
    async carregaDados() {
      this.loading = true;
      try {
        this.dadosHistorico = await Requester.laboratorio.private.buscaHistoricoInsumo(
          this.laboratorioId,
          this.produtoEstoque.id,
        );
        this.montaItens();
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
});
