var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CustomDialog',{attrs:{"value":_vm.dialog.form,"title":_vm.fornecedorSelecionado ? 'Alterar fornecedor' : 'Novo fornecedor',"icon":"mdi-truck","color":_vm.fornecedorSelecionado ? 'warning' : 'primary',"width":800},on:{"input":function($event){_vm.dialog.form = false}}},[_c('FornecedoresFormCriarAlterar',{attrs:{"fornecedor":_vm.fornecedorSelecionado},on:{"cancelar":function($event){_vm.dialog.form = false},"fornecedor-criado":function($event){return _vm.fornecedorCriado($event)},"fornecedor-alterado":function($event){return _vm.fornecedorAlterado($event)}}})],1),_c('CustomDialog',{attrs:{"value":_vm.dialog.ver,"title":"Dados do fornecedor","icon":"mdi-truck","color":"info","width":800},on:{"input":function($event){_vm.dialog.ver = false;
      _vm.fornecedorSelecionado = null;}}},[(_vm.fornecedorSelecionado)?_c('FornecedoresCardInfo',{attrs:{"fornecedor":_vm.fornecedorSelecionado}}):_vm._e()],1),_c('h2',{staticClass:"d-flex align-center text--secondary text-h4"},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v("mdi-truck")]),_vm._v(" Fornecedores ")],1),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"d-flex align-center justify-space-between my-3"},[_c('v-text-field',{attrs:{"placeholder":"Buscar ...","append-icon":"mdi-magnify","dense":"","rounded":"","filled":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.fornecedorSelecionado = null;
        _vm.dialog.form = true;}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Adicionar ")],1)],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"mt-3",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.fornecedores,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"warning","small":""},on:{"click":function($event){return _vm.alteraFornecedor(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":""}},[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Alterar informações do fornecedor")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.selecionaFornecedor(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar mais informações do fornecedor")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }