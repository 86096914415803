


































































import Vue from "vue";

import { Pedido } from "../../../../back/src/models/Pedido";
import { getPedidoStatusColor, getPedidoStatusText } from "@/utils/functions";

export default Vue.extend({
  name: "PedidosInfo",
  props: {
    pedido: {
      type: null || (Object as () => Pedido),
      default: null,
    },
  },
  methods: {
    getPedidoStatusText,
    getPedidoStatusColor,
  },
});
