import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

interface UserState {
  id: number;
  nome: string;
  email: string;
}
interface AuthInfo {
  user: UserState;
  rules: any;
}

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: null as null | UserState,
  },
  getters: {
    user: (state) => state.user,
  },
  mutations: {
    setUser(state, user: UserState) {
      state.user = user;
    },
    setUserName(state, nome: string) {
      if (state.user) {
        state.user.nome = nome;
      }
    },
    clearUser(state) {
      state.user = null;
    },
  },
  actions: {
    login(context, authInfo: AuthInfo) {
      context.commit("setUser", authInfo.user);
    },
    logout(context) {
      context.commit("setUser", null);
    },
    setUserName(context, nome: string) {
      context.commit("setUserName", nome);
    },
  },
  modules: {},
});
