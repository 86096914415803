<template>
  <div>
    <v-form v-model="valid" ref="formDetalheInsumo">
      <div class="form-group" v-for="(item, index) in produtos" :key="index">
        <v-divider v-if="produtos.length > 1" class="mb-6"></v-divider>
        <v-row class="ml-2 mr-2" dense>
          <v-col cols="4">
            <v-text-field
              v-model="produtos[index].lote"
              label="Lote"
              outlined
              dense
              required
              :rules="rules.rulesRequired"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <InputData
              label="Validade"
              v-model="produtos[index].data_validade"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="produtos[index].localizacao"
              label="Localização"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-2 mr-2" dense>
          <v-col cols="3">
            <v-autocomplete
              :items="embalagens"
              item-text="nome"
              item-value="id"
              v-model="produtos[index].embalagem_id"
              label="Embalagem"
              outlined
              dense
              required
              :rules="rules.rulesRequired"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="produtos[index].qtde_por_embalagem"
              :label="`Qtde por Embalagem${unidade ? ` (${unidade})` : ''}`"
              outlined
              dense
              :suffix="unidade ? `(${unidade})` : ''"
              required
              :rules="rules.rulesRequired"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="produtos[index].qtde_de_embalagem"
              label="Qtde de Embalagens"
              outlined
              dense
              required
              :rules="rules.rulesRequired"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              :value="
                produtos[index].qtde_de_embalagem *
                produtos[index].qtde_por_embalagem
              "
              disabled
              label="Total"
              outlined
              dense
              :suffix="unidade ? `(${unidade})` : ''"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn block color="error" @click="removerProduto(index)">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>

<script>
import Vue from "vue";
import Requester from "@/requester";
import InputData from "../InputData.vue";
import { rulesRequired } from "../../utils/rules";
import { mask } from "vue-the-mask";

export default Vue.extend({
  name: "ProdutoEntradaLoteForm",
  components: {
    InputData,
  },
  props: {
    unidade: {
      type: String,
      default: "",
    },
  },
  directives: { mask },
  data() {
    return {
      produtos: [
        {
          lote: null,
          embalagem_id: null,
          qtde_de_embalagem: null,
          qtde_por_embalagem: null,
          total: null,
          data_validade: "",
          localizacao: null,
        },
      ],
      embalagens: [],
      loading: false,
      valid: false,
      rules: {
        rulesRequired,
      },
    };
  },
  mounted() {
    this.carregaDados();
  },
  methods: {
    isValid() {
      return this.$refs.formDetalheInsumo.validate();
    },
    async carregaDados() {
      this.loading = true;
      try {
        this.embalagens = await Requester.embalagem.private.recuperaEmbalagens();
        this.$emit("atualiza-produtos", this.produtos);
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    removerProduto(index) {
      if (index >= 0) {
        this.produtos.splice(index, 1);
      }
    },
  },
});
</script>

<style scoped></style>
