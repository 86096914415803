import { PedidoStatusEnum } from "../../../back/src/types/constants";
import { pedidoStatusColor, pedidoStatusText } from "./constants";

export function getPedidoStatusText(status: PedidoStatusEnum): string {
  return pedidoStatusText?.[status] || "";
}

export function getPedidoStatusColor(status: PedidoStatusEnum): string {
  return pedidoStatusColor?.[status] || "";
}
