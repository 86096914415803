













import Vue from "vue";

export default Vue.extend({
  name: "NaoPermitido",
  data() {
    return {
      text: "Você não está autorizado(a) a acessar esta página",
    };
  },
  computed: {
    redirect() {
      return this.$route.query?.redirect || null;
    },
  },
});
