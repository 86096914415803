<template>
  <div>
    <v-card elevation="6" color="basil" flat>
      <v-card-text>
        <v-data-table
          :search="search"
          :headers="headersEnviadas"
          :items="estoque"
        >
          <template #top>
            <v-row class="my-2">
              <v-text-field
                v-model="search"
                placeholder="Buscar ..."
                append-icon="mdi-magnify"
                class="mx-2"
                dense
                rounded
                filled
                hide-details
              />
              <v-btn class="mr-2" color="primary" @click="dialog.criar = true">
                <v-icon left>mdi-plus</v-icon>
                Adicionar
              </v-btn>
            </v-row>
          </template>
          <template v-slot:item.saida>
            <v-btn color="primary">
              <v-icon small>mdi-arrow-collapse-down</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.adicionar>
            <v-btn color="primary">
              <v-icon small>mdi-arrow-collapse-up</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.acoes>
            <v-btn color="primary">
              <v-icon small>mdi-chart-bar-stacked</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "SolicitacoesEnviadas",
  data() {
    return {
      search: "",
    };
  },
  computed: {
    headersEnviadas() {
      return [
        {
          text: "CAS",
          value: "cas",
        },
        {
          text: "Produto",
          value: "nome",
        },
        {
          text: "Quantidade",
          value: "quantidade",
        },
        {
          text: "Unidade",
          value: "unidade",
        },

        {
          text: "Status",
          value: "adicionar",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
