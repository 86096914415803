











import Vue from "vue";
export default Vue.extend({
  name: "CustomColInfo",
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    cols: {
      type: Number,
      default: 12,
    },
    sm: {
      type: Number,
      default: 0,
    },
    md: {
      type: Number,
      default: 0,
    },
    classContent: {
      type: String,
      default: "",
    },
  },
  computed: {
    smComputed() {
      return this.sm || this.cols;
    },
    mdComputed() {
      return this.md || this.smComputed;
    },
  },
});
