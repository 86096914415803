<template>
  <div>Perfil</div>
</template>

<script>
export default {
  name: "Perfil",
};
</script>

<style lang="scss" scoped></style>
