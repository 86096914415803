import axios from "axios";
import qs from "querystring";

import auth from "./auth";
import embalagem from "./embalagem";
import insumo from "./insumo";
import laboratorio from "./laboratorio";
import unidade from "./unidade";
import users from "./users";
import relatorio from "./relatorio";

// backend base URL
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// send authentication cookie
axios.defaults.withCredentials = true;
// query string serializer
axios.defaults.paramsSerializer = (params) => qs.stringify(params);

export default {
  auth,
  embalagem,
  insumo,
  laboratorio,
  unidade,
  users,
  relatorio,
};
