import axios from "axios";
import { ProdutoEstoque } from "../../../back/src/types/formData";

const saldoMesAnterior = async (
  laboratorioId: number,
  mes: string,
): Promise<ProdutoEstoque[]> => {
  const res = await axios.get<ProdutoEstoque[]>("relatorio/saldo", {
    params: {
      laboratorioId,
      mes,
    },
  });
  return res.data;
};

const entradasSaidasMes = async (
  laboratorioId: number,
  mes: string,
): Promise<unknown> => {
  const res = await axios.get<unknown>("relatorio/entradas_saidas", {
    params: {
      laboratorioId,
      mes,
    },
  });
  return res.data;
};

export default {
  private: {
    saldoMesAnterior,
    entradasSaidasMes,
  },
};
