import Vue from "vue";
import VueTheMask from "vue-the-mask";
import App from "./App.vue";

// vue-router - https://router.vuejs.org/
import router from "./router";

// vuex - https://vuex.vuejs.org/
import store from "./store";

// plugins
import vuetify from "./plugins/vuetify";

// filters
import "./filters/formatDate";
import "./filters/formatDateOnly";

Vue.config.productionTip = false;
Vue.use(VueTheMask as any);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
