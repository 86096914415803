

























































































































































import Vue from "vue";
import { version } from "../../package.json";
import Requester from "@/requester";

export default Vue.extend({
  name: "LayoutPrincipal",
  data: () => ({
    version,
    loading: {
      logout: false,
    },
    drawer: false,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    laboratorioId() {
      if (this.$store.getters.user) {
        return this.$store.getters.user.laboratorio_id;
      }
      return null;
    },
  },
  methods: {
    async logout() {
      this.loading.logout = true;
      try {
        await Requester.auth.public.logout();
        this.$store.dispatch("logout");
        this.$root.$emit("showToast", {
          text: "Logout realizado com sucesso, até breve!",
          color: "success",
        });
        this.$router.push({ name: "Home" });
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.toString(),
          color: "error",
        });
      } finally {
        this.loading.logout = false;
      }
    },
    userLabAdmin() {
      return this.user?.papel_id === 1;
    },
    userSysAdmin() {
      return !!this.user?.is_admin;
    },
  },
});
