<template>
  <div>
    <div class="text--secondary text-h6 d-flex mx-4 mb-2">
      Produtos
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="adicionarProduto()">+ Saída</v-btn>
    </div>
    <v-divider class="mb-6"></v-divider>
    <v-row class="ml-2" dense v-for="(saida, index) in saidas" :key="index">
      <v-col cols="6">
        <v-combobox
          :items="estoque"
          item-text="nome"
          label="Produto"
          outlined
          dense
        ></v-combobox>
      </v-col>
      <v-col cols="3">
        <v-text-field label="Lote" outlined dense></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="saida.quantidade"
          label="Quantidade"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-btn color="error" @click="removerProduto(index)">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "FormSaidaUsoInterno",
  props: {
    estoque: {
      type: Array,
    },
  },
  data() {
    return {
      saidas: [
        {
          insumo_id: null,
          quantidade: null,
          lote: null,
        },
      ],
    };
  },
  methods: {
    adicionarProduto() {
      this.saidas.push({
        insumo_id: null,
        quantidade: null,
        lote: null,
      });
    },
    removerProduto(index) {
      if (index >= 0) {
        this.saidas.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
