import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";

import routesAuth from "./auth";
import routesUsers from "./users";
import routesLabs from "./laboratorios";
import routesAdmin from "./admin";

import store from "@/store";

import Entradas from "@/pages/Entradas.vue";
import Perfil from "@/pages/Perfil.vue";
import Produtos from "@/pages/Produtos.vue";
import Saidas from "@/pages/Saidas.vue";
import Solicitacoes from "@/pages/Solicitacoes.vue";
import MeusDados from "@/pages/MeusDados.vue";
import Insumos from "@/pages/Insumos.vue";
import Home from "@/pages/Home.vue";
import NaoPermitido from "@/pages/NaoPermitido.vue";
import requester from "@/requester";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "Principal",
      title: "Home",
      icon: "",
      public: true,
    },
  },
  {
    path: "/meus_dados",
    name: "MeusDados",
    component: MeusDados,
    meta: {
      layout: "Principal",
      title: "Meus dados",
      icon: "mdi-account-cog",
      public: false,
    },
  },
  {
    path: "/insumos",
    name: "Insumos",
    component: Insumos,
    meta: {
      layout: "Principal",
      title: "Insumos",
      icon: "mdi-flask",
      public: false,
    },
  },
  {
    path: "/entradas",
    name: "Entradas",
    component: Entradas,
    meta: {
      layout: "Principal",
      title: "Entradas",
      icon: "mdi-flask-empty-plus-outline",
      public: false,
    },
  },
  {
    path: "/perfil",
    name: "Perfil",
    component: Perfil,
    meta: {
      public: false,
    },
  },
  {
    path: "/produtos",
    name: "Produtos",
    component: Produtos,
    meta: {
      public: false,
    },
  },
  {
    path: "/saidas",
    name: "Saidas",
    component: Saidas,
    meta: {
      layout: "Principal",
      title: "Saídas",
      icon: "mdi-flask-empty-minus-outline",
      public: false,
    },
  },
  {
    path: "/solicitacoes",
    name: "Solicitacoes",
    component: Solicitacoes,
    meta: {
      layout: "Principal",
      title: "Solicitações",
      icon: "mdi-swap-vertical",
      public: false,
    },
  },
  {
    path: "/nao_permitido",
    name: "NaoPermitido",
    component: NaoPermitido,
    meta: {
      layout: "Principal",
      title: "Acesso negado",
      icon: "mdi-ban",
      public: true,
    },
  },
  ...routesAuth,
  ...routesUsers,
  ...routesLabs,
  ...routesAdmin,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  // Pega user na store
  let usuarioStore = store.getters.user;
  // confere usuario
  if (usuarioStore) {
    try {
      await requester.auth.private.checkSession();
    } catch (err) {
      store.dispatch("logout");
      usuarioStore = store.getters.user;
    }
  }
  // Rota publica
  if (to?.meta?.public) {
    next();
    return;
  }

  // Rota privada
  if (!usuarioStore) {
    next({ name: "Login" });
    return;
  } else {
    // verifica permissao: TODO
    console.log("CHECAR PERMISSAO", usuarioStore);
    // const redirect = from.name ? from.path : null;
    // next({ name: "NaoPermitido", query: { redirect } });
    next();
    return;
  }

  // const aliases = usuarioStore?.papeis?.map((papel) => papel?.alias) || [];
  // const papeisRota = to?.meta?.papeis || [];
  // if (papeisRota.length > 0 && intersection(aliases, papeisRota).length === 0) {
  //   next({ name: "ErrorNaoPermitido" });
  //   return;
  // }
});

export default router;
