








































import Vue from "vue";
import moment from "moment";

export default Vue.extend({
  name: "InputData",
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    minDate: {
      default: null,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      data: this.value,
      dataFormatada: moment(new Date(this.value)).isValid()
        ? moment(new Date(this.value)).utc().format("DD/MM/YYYY")
        : "",
      menu: false,
      rules: {
        data: [
          (v: string) => (this.required ? !!v : true) || "Campo obrigatório",
          (v: string) => {
            if (!this.required && !v) {
              return true;
            }
            const data = v.split("/").reverse().join("-");
            return moment(new Date(data)).isValid() || "Data inválida";
          },
        ],
      },
    };
  },
  watch: {
    value(val) {
      this.data = moment(new Date(val)).isValid() ? val : "";
      this.dataFormatada = val ? val.split("-").reverse().join("/") : val;
    },
  },
  methods: {
    onInputData(data: string) {
      this.menu = false;
      this.$emit("input", data);
    },
    onInputDataFormatada(dataFormatada: string) {
      const data = dataFormatada.split("/").reverse().join("-");
      this.$emit("input", data);
    },
  },
});
