










import Vue from "vue";

import Requester from "@/requester";

export default Vue.extend({
  name: "LayoutLogin",
  data: () => ({
    loading: {
      logout: false,
    },
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async logout() {
      this.loading.logout = true;
      try {
        await Requester.auth.public.logout();
        this.$store.dispatch("logout");
        this.$root.$emit("showToast", {
          text: "Logout realizado com sucesso, até breve!",
          color: "success",
        });
        this.$router.push({ name: "Home" });
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.toString(),
          color: "error",
        });
      } finally {
        this.loading.logout = false;
      }
    },
  },
});
