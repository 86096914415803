import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/users/create",
    name: "UsersCreate",
    component: () =>
      import(
        /* webpackChunkName: "users.login" */ "@/pages/users/UsersCreate.vue"
      ),
    meta: {
      layout: "Login",
      public: true,
    },
  },
  {
    path: "/users/activate",
    name: "UsersActivate",
    component: () =>
      import(
        /* webpackChunkName: "users.UsersActivate" */ "@/pages/users/UsersActivate.vue"
      ),
    meta: {
      layout: "Login",
      public: true,
    },
  },
  {
    path: "/users/email",
    name: "UsersEmailUpdate",
    component: () =>
      import(
        /* webpackChunkName: "users.UsersEmailUpdate" */ "@/pages/users/UsersEmailUpdate.vue"
      ),
    meta: {
      layout: "Login",
      public: true,
    },
  },
  {
    path: "/users/recovery",
    name: "UsersRecovery",
    component: () =>
      import(
        /* webpackChunkName: "users.UsersRecovery" */ "@/pages/users/UsersRecovery.vue"
      ),
    meta: {
      layout: "Login",
      public: true,
    },
  },
  {
    path: "/users/password",
    name: "UsersPassword",
    component: () =>
      import(
        /* webpackChunkName: "users.UsersPassword" */ "@/pages/users/UsersPassword.vue"
      ),
    meta: {
      layout: "Login",
      public: true,
    },
  },
];

export default routes;
