<template>
  <div>
    <v-card class="mt-2">
      <v-tabs
        v-model="tab"
        background-color="teal lighten-5"
        color="basil"
        grow
      >
        <v-tab href="#recebidas">eles querem</v-tab>
        <v-tab href="#enviadas">eu quero</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="recebidas">
          <SolicitacoesRecebidas />
        </v-tab-item>
        <v-tab-item value="enviadas">
          <SolicitacoesEnviadas />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import SolicitacoesRecebidas from "../components/TabelaSolicitacaoRecebida.vue";
import SolicitacoesEnviadas from "../components/TabelaSolicitacaoEnviada.vue";

export default {
  name: "Solicitacoes",
  components: {
    SolicitacoesRecebidas,
    SolicitacoesEnviadas,
  },

  data() {
    return {
      searchEnviadas: "",
      tab: "",
    };
  },
};
</script>

<style lang="scss" scoped></style>
