var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CustomDialog',{attrs:{"value":_vm.dialog.saida,"title":"Saída de insumo","icon":"mdi-flask-minus","color":"error","width":1000},on:{"input":function($event){return _vm.fechaDialogSaida()}}},[(_vm.produtoEstoqueSelecionado)?_c('ProdutoSaidaForm',{attrs:{"laboratorioId":_vm.user.laboratorio_id,"produtoEstoque":_vm.produtoEstoqueSelecionado},on:{"saidas-criadas":function($event){return _vm.novasSaidasCriadas()},"cancelar":function($event){return _vm.fechaDialogSaida()}}}):_vm._e()],1),_c('CustomDialog',{attrs:{"value":_vm.dialog.historico,"title":"Histórico do insumo","icon":"mdi-history","color":"secondary","width":1000},on:{"input":function($event){_vm.dialog.historico = false}}},[(_vm.dialog.historico && _vm.produtoEstoqueSelecionado)?_c('InsumoHistorico',{attrs:{"laboratorioId":_vm.user.laboratorio_id,"produtoEstoque":_vm.produtoEstoqueSelecionado}}):_vm._e()],1),_c('h2',{staticClass:"d-flex align-center text--secondary text-h4"},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v("mdi-home")]),_vm._v(" "+_vm._s(_vm.laboratorio.nome)+" - Estoque ")],1),_c('v-divider',{staticClass:"my-3"}),_c('v-text-field',{staticClass:"my-3",attrs:{"placeholder":"Buscar ...","append-icon":"mdi-magnify","dense":"","rounded":"","filled":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"mt-2",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.estoqueSomado},scopedSlots:_vm._u([{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.quantidade_total)+" "+_vm._s(item.unidade))])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.darSaida(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-flask-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Registrar saidas de insumos")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.verHistorico(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-history")])],1)]}}],null,true)},[_c('span',[_vm._v("Consultar informações detalhadas")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }