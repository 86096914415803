import axios from "axios";
import { Unidade } from "../../../back/src/models/Unidade";

const recuperaUnidades = async (): Promise<Unidade[]> => {
  const res = await axios.get<Unidade[]>("/public/unidades");
  return res.data;
};

export default {
  public: {
    recuperaUnidades,
  },
};
