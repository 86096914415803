















import { DadosRelatorio } from "@/utils/constants";
import Vue from "vue";
import * as XLSX from "xlsx";

export default Vue.extend({
  name: "ExportarDadosBtn",
  props: {
    dados: {
      type: Array,
      default: () => [] as DadosRelatorio[],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dadosMapeados() {
      return this.dados.map((el: DadosRelatorio) => {
        return {
          CAS: el.cas,
          "Nome do insumo": el.nome,
          Unidade: el.unidade,
          "Saldo no mês anterior": el.saldo_anterior,
          Compras: el.compras,
          "Empréstimos recebidos": el.emprestimos_in,
          "Consumo/Descarte": el.consumo,
          "Empréstimos feitos (doações)": el.emprestimos_out,
          "Saldo final no período": this.saldoAtual(el),
        };
      });
    },
  },
  methods: {
    saldoAtual(item: DadosRelatorio) {
      return (
        item.saldo_anterior +
        item.compras +
        item.emprestimos_in -
        item.consumo -
        item.emprestimos_out
      );
    },
    baixarDados() {
      const data = new Date().toJSON().slice(0, 10);
      const filename = `${data}_Relatorio-GerIR.xlsx`;

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(this.dadosMapeados);
      XLSX.utils.book_append_sheet(workbook, worksheet, "relatorio");
      XLSX.writeFile(workbook, filename, {
        bookType: "xlsx",
      });
    },
  },
});
