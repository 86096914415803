




































































































import Vue from "vue";

import { getPedidoStatusColor, getPedidoStatusText } from "@/utils/functions";

import { Pedido } from "../../../../back/src/models/Pedido";
import PedidosDialogCriarPedido from "./PedidosDialogCriarPedido.vue";
import { pedidoStatusText } from "@/utils/constants";
import { map } from "lodash";
import { ViewPedidoResumo } from "../../../../back/src/models/ViewPedidoResumo";
import PedidosDialogDetalhes from "./PedidosDialogDetalhes.vue";
import { PedidoStatusEnum } from "../../../../back/src/types/constants";
import PedidosDialogReceber from "./PedidosDialogReceber.vue";

export default Vue.extend({
  name: "PedidosTabDestino",
  components: {
    PedidosDialogCriarPedido,
    PedidosDialogDetalhes,
    PedidosDialogReceber,
  },
  props: {
    pedidos: {
      type: Array as () => Pedido[],
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialogCriarPedido: false,
    dialogDetalhes: false,
    dialogReceber: false,
    pedidoDetalhesId: null,
    pedidoReceberId: null,
    search: "",
    filterStatus: null,
    headers: [
      { value: "created", text: "Data" },
      { value: "insumo_nome", text: "Insumo" },
      { value: "quantidade", text: "Quantidade" },
      { value: "pedido_status_atual_status", text: "Status" },
      { value: "laboratorio_origem_nome", text: "Laboratório" },
      {
        value: "acao",
        text: "Ação",
        sortable: false,
        filterable: false,
        align: "center",
      },
      {
        value: "detalhes",
        text: "Detalhes",
        sortable: false,
        filterable: false,
        align: "center",
      },
    ],
  }),
  computed: {
    pedidosFiltrados() {
      if (!this.filterStatus) return this.pedidos;
      return this.pedidos.filter(
        (el: ViewPedidoResumo) =>
          el.pedido_status_atual_status === this.filterStatus,
      );
    },
    itemsPedidoStatus() {
      return map(pedidoStatusText, (text, value) => ({ text, value }));
    },
  },
  methods: {
    getPedidoStatusText,
    getPedidoStatusColor,
    podeReceber(pedido: ViewPedidoResumo) {
      return [PedidoStatusEnum.RETIRADO].includes(
        pedido?.pedido_status_atual_status,
      );
    },
    onDetalhes(pedidoId: number) {
      this.dialogDetalhes = true;
      this.pedidoDetalhesId = pedidoId;
    },
    onReceber(pedidoId: number) {
      this.dialogReceber = true;
      this.pedidoReceberId = pedidoId;
    },
    onNovoPedido(pedido: Pedido) {
      this.dialogCriarPedido = false;
      this.$emit("novo-pedido", pedido);
    },
    onPedidoAlterado(pedido: Pedido) {
      this.dialogReceber = false;
      this.$emit("pedido-alterado", pedido);
    },
  },
});
