












import Vue from "vue";

import requester from "@/requester";

import CustomDialog from "../CustomDialog.vue";
import { Pedido } from "../../../../back/src/models/Pedido";
import { getPedidoStatusColor, getPedidoStatusText } from "@/utils/functions";
import PedidosInfo from "./PedidosInfo.vue";

export default Vue.extend({
  name: "PedidosDialogDetalhes",
  components: {
    CustomDialog,
    PedidosInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    pedidoId: {
      type: Number || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      submitForm: false,
    },
    pedido: null as Pedido | null,
  }),
  computed: {
    laboratorioId() {
      return this.$store.getters.user?.laboratorio_id || 0;
    },
  },
  watch: {
    pedidoId() {
      this.buscaPedido();
    },
  },
  mounted() {
    this.buscaPedido();
  },
  methods: {
    getPedidoStatusText,
    getPedidoStatusColor,
    onInput(event: boolean) {
      this.$emit("input", event);
    },
    async buscaPedido() {
      if (!this.pedidoId) return;
      this.loading.pedido = true;
      try {
        this.pedido = await requester.laboratorio.private.buscaPedido(
          this.laboratorioId,
          this.pedidoId,
        );
      } catch (err) {
        this.$root.$emit("toastError", err?.response?.data || err.toString());
      }
      this.loading.pedido = false;
    },
  },
});
