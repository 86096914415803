<template>
  <div>
    <h1 class="font-weight-bold ml-2 text-h4 basil--text">
      <v-icon color="secondary" large left>mdi-{{ icone }}</v-icon>
      {{ titulo }}
    </h1>
    <p class="ml-2 mt-2">
      {{ descricao }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Titulo",
  props: {
    titulo: {
      type: String,
      default: "",
    },
    icone: {
      type: String,
      default: "",
    },
    descricao: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
